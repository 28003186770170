import React, {Component} from 'react'
import { connect } from 'react-redux'

export class LogoutComponentClass extends Component<any> {
  constructor(props: any) {
    super(props, '');
  }

  public render() {
    return (
      <div>
        Hold on while I am logging you out...
      </div>
    );
  }
}

export const LogoutComponent = connect(
  null,
  null,
)(LogoutComponentClass)
