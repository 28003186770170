import {DashboardMenuOption} from 'components/dashboard-menu/component'
import {PanelBarOption} from 'components/panel-bar/types'
import React from 'react'
import {AnalyticsComponent} from './component'
import {allSections} from './constants'

export const routes = allSections.map( (item: PanelBarOption) => {
  return {
    action: () => <AnalyticsComponent selectedItem={item}  />,
    path: DashboardMenuOption.analytics + (item.route.length === 0 ? '' : '/' + item.route),
  }
})
