import { DayAndTime } from 'components/icons/day-and-time'
import { NewScheduledContentIcon } from 'components/icons/new-scheduled-content'
import { NewUpcomingSchedule } from 'components/icons/new-upcoming-schedule'
import { PanelBarOption } from 'components/panel-bar/types'
import { CalendarComponent } from 'components/schedule-editor/calendar/component'
import { MobileScheduleSettings } from 'components/schedule-editor/mobile/scheduleSettings'
import { MobileScheduleUpcomingIssues } from 'components/schedule-editor/mobile/upcomingIssues'
import { ScheduledContent } from 'components/schedule-editor/scheduled-content/component'
import { ScheduleSettings } from 'components/schedule-editor/scheduleSettings'
import React from 'react'
import {BillingPlan, BillingPlanDetailCode, canAccessPlanFeature} from "../../shared_server_client/types/billing_plan";

const dayAndTime = <DayAndTime svgwidth={32.5} svgheight={33.7} />
const scheduledContent = <NewScheduledContentIcon svgwidth={32.5} svgheight={33.7} />
const upcoming = <NewUpcomingSchedule svgwidth={32.5} svgheight={33.7} />

export const allSections: PanelBarOption[] = [
  {
    category: '',
    component: ScheduleSettings,
    icon: dayAndTime,
    name: 'Day & Time',
    route: '',
  },
  {
    category: '',
    component: CalendarComponent,
    icon: upcoming,
    name: 'Upcoming Newsletters',
    route: 'calendar',
  },
  {
    category: '',
    component: ScheduledContent,
    icon: scheduledContent,
    name: 'Scheduled Content',
    route: 'scheduled-content',
    feature_name: BillingPlanDetailCode.SCHEDULED_CONTENT
  },
]

export const sections = (billingPlan: BillingPlan): PanelBarOption[] => {
  return allSections.map((item) => {
    if (item.feature_name) {
      item.hasFeature = canAccessPlanFeature(item.feature_name, billingPlan)
    }

    return item
  })
}

export const mobileSections: PanelBarOption[] = [
  {
    category: '',
    component: MobileScheduleSettings,
    icon: dayAndTime,
    name: 'Day & Time',
    route: '',
  },
  {
    category: '',
    component: MobileScheduleUpcomingIssues,
    icon: upcoming,
    name: 'Upcoming',
    route: 'calendar',
  },
]
