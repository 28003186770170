import { DashboardMenu, DashboardMenuOption } from 'components/dashboard-menu/component'
import { GenericNavigatorSelectedItemProps } from 'components/generic-navigator/component'
import { DesktopNavigator } from 'components/generic-navigator/desktop'
import * as Globals from 'generic/globals'
import React, {Component} from 'react'
import * as Constants from './constants'

const headerImgElement = <img src={Globals.ADMIN_IMG} className="panel-generic-header-icon" />

export class AdminComponent extends Component<GenericNavigatorSelectedItemProps> {
  constructor(props: GenericNavigatorSelectedItemProps) {
    super(props)
  }

  public render() {
    return (
      <div>
        <DashboardMenu selected={DashboardMenuOption.adminContentLibrary} />
        <div>
          <DesktopNavigator
                selectedItem={this.props.selectedItem}
                sections={Constants.sections}
                baseRoute={DashboardMenuOption.admin}
                title="Admin"
                subTitle="Manage newsletters"
                headerIcon={headerImgElement}>
          </DesktopNavigator>
        </div>
      </div>
    );
  }
}
