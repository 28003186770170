import { DashboardMenuOption } from 'components/dashboard-menu/component'
import { PanelBarOption } from 'components/panel-bar/types'
import React from 'react'
import { ScheduleComponent } from './component'
import { allSections } from './constants'
import { MobileScheduleComponent } from './mobile-component'

export const routes = allSections.map((item: PanelBarOption) => (
  {
    action: (action) => action.store.getState().config.isDesktop
    ? <ScheduleComponent selectedItem={item} />
    : <MobileScheduleComponent selectedItem={item} />,
    path: DashboardMenuOption.schedule + (item.route.length === 0 ? '' : '/' + item.route),
  }
))
