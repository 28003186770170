
declare const RECAPTCHA_KEY: string

export const loadRecaptcha = () => {
  if ( RECAPTCHA_KEY ) {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_KEY}`
    script.async = true
    document.body.appendChild(script)
  }
}

export const withRecaptcha = (action, handler) => {
  if ( RECAPTCHA_KEY ) {
    const recaptcha: any = (window as any).grecaptcha.enterprise
    recaptcha.ready(() => {
      recaptcha.execute(RECAPTCHA_KEY, {action})
        .then((token) => handler(token))
    })
  } else {
    handler(null)
  }
}

export const getRecaptchaToken = (action) => {
  if ( RECAPTCHA_KEY && (window as any).grecaptcha ) {
    const recaptcha: any = (window as any).grecaptcha.enterprise
    return new Promise((resolve) => {
      recaptcha.ready(() => {
        recaptcha.execute(RECAPTCHA_KEY, {action})
          .then((token) => resolve(token))
      })
    })
  } else {
    return Promise.resolve(null)
  }
}
