import * as addComponents from 'components/add-contacts/component'
import {PanelBarOption} from 'components/panel-bar/types'
import {SegmentUpsertConnectedComponent} from 'components/segmentation/editComponent'
import {ConnectedCommunitySegmentsComponent} from 'components/segmentation/entrycomponent'
import { SubscriberDetailsContainer } from 'components/subscriber-details/component'
import {AddSubscriber} from 'components/subscriber-grid/addSubscriber'
import * as Components from 'components/subscriber-grid/component'
import * as Globals from 'generic/globals'
import * as React from 'react'

import { AddInterestsComponent } from 'components/add-interests/component'
import {AddContacts} from 'components/icons/addcontacts'
import {AllContacts} from 'components/icons/allcontacts'
import {BulkArchive} from 'components/icons/bulk-archive'
import {BulkUnsubscribe} from 'components/icons/bulk-unsubscribe'
import {SegmentationIcon} from 'components/icons/segmentation'
import {Subscribed} from 'components/icons/subscribed'
import {Unsubscribed} from 'components/icons/unsubscribed'
import { ImportHistoryComponent } from 'components/import-history/component'
import {
  BulkArchiveComponent,
  BulkResubscribeComponent,
  BulkUnsubscribeComponent
} from 'components/subscriber-grid/bulk-upload'
import {ImportContacts} from 'components/subscriber-grid/import-contacts'
import {SubscriberCounts} from 'components/subscriber-grid/mobile/mobile-component'
import {MobileSubscriberDetailsContainer} from 'components/subscriber-grid/mobile/subscriber-details'
import {SearchSubscribersMobile} from 'components/subscriber-grid/mobile/subscriber-search'
import { WidgetsTemplateCodes } from 'components/subscriber-grid/template-codes/component'

const imgElement = <img src={Globals.SUBSCRIBERS_IMG} className="panel-generic-item-icon"/>

const addContacts = <AddContacts svgwidth={30.4} svgheight={26.9}/>
const allContacts = <AllContacts svgwidth={30.4} svgheight={26.9}/>
const bulkArchive = <BulkArchive svgwidth={30.4} svgheight={26.9}/>
const bulkUnsubscribe = <BulkUnsubscribe svgwidth={30.4} svgheight={26.9}/>
const subscribed = <Subscribed svgwidth={30.4} svgheight={26.9}/>
const unsubscribed = <Unsubscribed svgwidth={30.4} svgheight={26.9}/>
const segments = <SegmentationIcon svgwidth={30.4} svgheight={26.9}/>

export const mobileSections: PanelBarOption[] = [
  { category: '',
    component: SubscriberCounts,
    icon: allContacts,
    name: 'Settings',
    route: '',
  },
  {
    category: '',
    component: AddSubscriber,
    hide: true,
    icon: imgElement,
    name: 'Add Contact',
    route: 'add-contact',
  },
  {
    category: '',
    component: SearchSubscribersMobile,
    hide: true,
    icon: imgElement,
    name: 'Search Contacts',
    route: 'search',
  },
  {
    category: '',
    component: MobileSubscriberDetailsContainer,
    hide: true,
    icon: imgElement,
    name: 'Details',
    route: 'detail',
  },
]

export const allSections: PanelBarOption[] = [
  {
    category: '',
    component: Components.SubscribersHomePage,
    icon: allContacts,
    name: 'All Contacts',
    route: '',
  },
  {
    category: '',
    component: Components.SubscribedSubscribersGrid,
    icon: subscribed,
    name: 'Subscribed',
    route: 'subscribed',
    hide: true,
  },
  {
    category: '',
    component: Components.UnsubscribedSubscribersGrid,
    icon: unsubscribed,
    name: 'Unsubscribed',
    route: 'unsubscribed',
    hide: true,
  },
  {
    category: '',
    component: ImportContacts,
    icon: addContacts,
    name: 'Import Contacts',
    route: 'add-contacts',
  },
  {
    category: '',
    component: addComponents.AddContactsComponent,
    hide: true,
    icon: addContacts,
    name: 'Import Contacts',
    route: 'import-csv',
  },
  {
    category: '',
    component: addComponents.ReplaceAllContactsComponent,
    hide: true,
    icon: addContacts,
    name: 'Replace All Contacts',
    route: 'replace-csv',
  },
  {
    category: '',
    component: addComponents.AddContactsViaIntegration,
    hide: true,
    icon: addContacts,
    name: 'Import Contacts from Integrations',
    route: 'contact-integration',
  },
  {
    category: '',
    component: AddSubscriber,
    hide: true,
    icon: imgElement,
    name: 'Add Contact',
    route: 'add-contact',
  },
  {
    category: '',
    component: BulkUnsubscribeComponent,
    icon: bulkUnsubscribe,
    name: 'Bulk Unsubscribe',
    route: 'bulk-unsubscribe',
  },
  {
    category: '',
    component: BulkResubscribeComponent,
    icon: bulkUnsubscribe,
    name: 'Bulk Resubscribe',
    route: 'bulk-resubscribe',
  },
  {
    category: '',
    component: BulkArchiveComponent,
    icon: bulkArchive,
    name: 'Bulk Archive',
    route: 'bulk-archive',
  },
  {
    category: '',
    component: ConnectedCommunitySegmentsComponent,
    hide: true,
    icon: segments,
    name: 'Segments_Old',
    route: 'segments_old',
  },
  {
    category: '',
    component: SegmentUpsertConnectedComponent,
    hide: true,
    icon: imgElement,
    name: 'Edit Segment',
    route: 'segments/detail',
  },
  {
    category: '',
    component: SubscriberDetailsContainer,
    hide: true,
    icon: imgElement,
    name: 'Details',
    route: 'detail',
  },
  {
    category: '',
    component: null,
    hide: true,
    icon: imgElement,
    name: 'Search Contacts',
    route: 'search',
  },
  {
    category: '',
    component: WidgetsTemplateCodes,
    icon: segments,
    name: 'Segments',
    route: 'segments',
  },
  {
    category: '',
    component: AddInterestsComponent,
    icon: addContacts,
    hide: true,
    name: 'Import Interests',
    route: 'import-interest-csv',
  },
  {
    category: '',
    component: ImportHistoryComponent,
    icon: addContacts,
    name: 'Import History',
    route: 'import-history',
  },
]

export const sections = (isSuperUser: boolean, options: any) => {
  const hasSegmentAccess: boolean = (options && options.hasSegmentAccess) || false
  const availableSections = allSections.filter((item) => {
    if (item.name === 'Segments') {
      return hasSegmentAccess
    } else if (item.name === 'Import Interests' || item.name === 'Bulk Resubscribe') {
      return isSuperUser
    } else {
      return true
    }
  })
  return availableSections
}
