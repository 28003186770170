import {DashboardMenuOption} from 'components/dashboard-menu/component'
import { MobileDashboardMenu } from 'components/dashboard-menu/mobile/mobile-component'
import { MobileDashboard } from 'components/dashboard/mobile/dashboard'
import React, {Component} from 'react'

export class MobileRootComponent extends Component {

  public render() {
    return (
      <div>
        <MobileDashboardMenu selected={DashboardMenuOption.dashboard} />
        <MobileDashboard/>
      </div>
    )
  }
}
