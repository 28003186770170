import React from 'react'
import {LogoutComponent} from './component'
import {LOGOUT_BASE_ROUTE} from './constants'

export const routes = [
  {
    action: () => <LogoutComponent />,
    path: LOGOUT_BASE_ROUTE,
  },
]
