import * as Redux from 'redux'
import * as Constants from './constants'

export const reducer = Redux.combineReducers({
  email: (state = null, {type, email}) => type === Constants.FA_SET_EMAIL ? email : state,
  isLoading: (state = false, {type, isLoading}) =>
    type === Constants.FA_SET_IS_LOADING ? isLoading : state,
  serverError: (state = false, {type, serverError}) =>
    type === Constants.FA_SET_SERVER_ERROR ? serverError : state,
  resendSuccess: (state = false, {type, resendSuccess}) =>
    type === Constants.FA_SET_RESEND_SUCCESS ? resendSuccess : state,
})
