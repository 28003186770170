import React, { Component } from 'react'
import { DashboardMenu, DashboardMenuOption } from '../../components/dashboard-menu/component'
import { EmailTemplatePreview } from 'components/email-layout/templatePreview';

export class TemplatePreviewComponent extends Component {
  public render() {
    return (
      <div>
        <DashboardMenu selected={DashboardMenuOption.templatePreview} />
        <div>
          <EmailTemplatePreview />
        </div>
      </div>
    );
  }
}