import {RasaLogo} from 'components/icons/rasalogo'
import {Input} from 'components/input/component'
import {preventDefaultThen} from 'components/link/component'
import { Loading } from 'components/loading'
import React, {Component} from 'react'
import { connect } from 'react-redux'
import * as ReactStrap from 'reactstrap'
import * as GlobalConstants from '../../constants'
import * as Utils from '../../generic/utility'
import * as Actions from './actions'
import './styles.css'

const {Button, Col, Form, Row} = ReactStrap

interface ForgotPasswordState {
  isLoading: boolean,
  serverError: boolean,
}

interface MapDispatchToProps {
  forgotPassword: any,
  setIsLoading: any,
  setServerError: any,
}

type Props = MapDispatchToProps & ForgotPasswordState

interface State {
  email: string,
  validEmail: boolean,
}

class ForgotPasswordComponentClass extends Component<Props, State> {
  constructor(props: Props) {
    super(props, '');
    this.state = {
      email: '',
      validEmail: true,
    }
  }

  public render() {
    return (
      <div className="forgot-password">
        <div className="dashboard-menu">
          <div className={'dashboard-menu-item dashboard-rasa-logo'}>
            <RasaLogo/>
          </div>
          <div className="dashboard-popup-menu-item clickable-item need-a-help">
            <a href={GlobalConstants.RASA_HELP_BASE_URL} target="_blank" rel="noopener">Help & Support</a>
          </div>
        </div>
        <Row className="forgot-password-wrapper">
          <Col md="6" lg="12" className="content">
            <h1>Forgot / Reset Password</h1>
            <p className="enter-paragraph">Enter your Email below and we will send a message to reset your password</p>
            <Form onSubmit={preventDefaultThen(() => {
              this.props.setServerError(false)
              this.props.setIsLoading(true)
              this.props.forgotPassword(this.state.email)
              })}>
              <ReactStrap.FormGroup className="forgot-password">
                <ReactStrap.Label className="input-label" for="email">Email</ReactStrap.Label>
                <Input
                  className={`input-text ${this.state.validEmail ? '' : 'invalid'} `}
                  type="text"
                  name="email"
                  onChange={(e) => {
                    this.updateField({name: 'email', value: e.target.value.trim()})
                    this.setState({validEmail: Utils.validateEmail(e.target.value.trim())})
                  }}
                  placeholder="email"
                />
              </ReactStrap.FormGroup>
              {this.props.serverError && <div className="invalid-credentials">Invalid Request!</div>}
              {this.props.isLoading && <Loading size="32"></Loading>}
              <Button className="btn-submit"
                disabled={!this.state.email.trim().length || !this.state.validEmail || this.props.isLoading}
              >Submit</Button>
            </Form>
          </Col>
        </Row>
      </div>
    )
  }

  private updateField(event: any) {
    this.setState({
      ...this.state,
      [event.name]: event.value,
    })
  }
}

export const ForgotPasswordComponent = connect(
  ({forgotPassword}: any) => ({
    isLoading: forgotPassword.isLoading,
    serverError: forgotPassword.serverError,
  }),
  {
    forgotPassword: Actions.submitForgotPassword,
    setIsLoading: Actions.setIsLoading,
    setServerError: Actions.setServerError,
  },
)(ForgotPasswordComponentClass)
