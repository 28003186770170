import React, {Component} from 'react'
import { connect } from 'react-redux'

class IntegrationsCallbackComponentClass extends Component<any> {
  constructor(props: any) {
    super(props, '');
  }

  public render() {
    return (
      <div>
        Redirecting...
      </div>
    );
  }
}

export const IntegrationsCallbackComponent = connect(
  null,
  null,
)(IntegrationsCallbackComponentClass)
