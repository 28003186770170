import { DashboardMenu, DashboardMenuOption } from 'components/dashboard-menu/component'
import { GenericNavigatorSelectedItemProps } from 'components/generic-navigator/component'
import { DesktopNavigator } from 'components/generic-navigator/desktop'
import {AllContacts} from 'components/icons/allcontacts'
import { RasaContext } from 'context'
import React, {Component} from 'react'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import * as Constants from './constants'

const allcontacts = <AllContacts svgwidth={61.2} svgheight={60.5}/>

interface SubscribersStates {
  isSuperUser?: boolean,
  hasSegmentAccess?: boolean,
}

export class BaseComponent extends Component<GenericNavigatorSelectedItemProps, SubscribersStates> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: GenericNavigatorSelectedItemProps) {
    super(props)
    this.state = {
      isSuperUser: false,
      hasSegmentAccess: false,
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.role),
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([role, activeCommunity]) => {
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.setState({
        isSuperUser: role === Roles.super_admin,
        hasSegmentAccess: avlFeatures.indexOf(BillingPlanDetailCode.SEGMENT_CODE) > -1,
      })
    })
  }

  public render() {
    return (
      <div>
        <DashboardMenu selected={DashboardMenuOption.contacts} />
        <div>
          <DesktopNavigator
                selectedItem={this.props.selectedItem}
                sections={Constants.sections(this.state.isSuperUser, {hasSegmentAccess: this.state.hasSegmentAccess})}
                baseRoute={DashboardMenuOption.contacts}
                title="Contacts"
                subTitle="Manage your contacts here"
                headerIcon={allcontacts}>
          </DesktopNavigator>
        </div>
      </div>
    );
  }
}
