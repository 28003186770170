import React, { Component } from 'react'
import { DashboardMenu, DashboardMenuOption } from '../../components/dashboard-menu/component'
import { TemplateLibrary } from '../../components/template-library/components'

export class TemplateLibraryComponent extends Component {
  public render() {
    return (
      <div>
        <DashboardMenu selected={DashboardMenuOption.templateLibrary} />
        <div>
          <TemplateLibrary />
        </div>
      </div>
    );
  }
}