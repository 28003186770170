import { DropdownOption } from 'components/dropdown/component'

export const FA_BASE_ROUTE = '/2fa-auth'
export const SUBMIT_2FA = 'SUBMIT_2FA'
export const RESEND_2FA = 'RESEND_2FA'
export const FA_SET_EMAIL = 'FA_SET_EMAIL'
export const FA_SET_IS_LOADING = 'FA_SET_IS_LOADING'
export const FA_SET_SERVER_ERROR = 'FA_SET_SERVER_ERROR'
export const FA_SET_RESEND_SUCCESS = 'FA_SET_RESEND_SUCCESS'
export const AllExpirationOptions: DropdownOption[] = [
  {
    key: '1',
    value: '1',
    description: 'This time only',
  },
  {
    key: '2',
    value: '30',
    description: '30 days',
  },
  {
    key: '3',
    value: '180',
    description: '180 days',
  },
]
