import get from 'lodash/get'
import * as Request from 'request'
import * as Constants from './constants'
import * as Types from './types'

export const linkedInLogin = () => ({type: Constants.LINKEDIN_LOGIN})

export const setFacebookAppId = (value: string) => ({type: Constants.SET_FACEBOOK_APP_ID, value})

export const loginInProgress = (value: boolean) => ({type: Constants.LOGIN_IN_PROGRESS, value})
export const loginFailed = (value: boolean, response: any) => {
  return ({
    message: get(response, 'payload.error.response.message') || '',
    type: Constants.LOGIN_FAILED,
    value,
  })
}
export const submitLogin = (payload: Types.LoginPayload) => Request.requestActionCreator(Constants.SUBMIT_LOGIN)
({
  method: 'post',
  payload,
  url: '/auth/dashboard/login',
})

export const googleLoginCallback = (value: string) => ({type: Constants.GOOGLE_LOGIN_CALLBACK, value})

export const getSSOClients = () => Request.requestActionCreator(Constants.SSO_CLIENTS)
({
  method: 'get',
  url: '/sso-clients',
})
