import {RasaLogo} from 'components/icons/rasalogo'
import {Input} from 'components/input/component'
import {preventDefaultThen} from 'components/link/component'
import { Loading } from 'components/loading'
import React, {Component} from 'react'
import { connect } from 'react-redux'
import * as ReactStrap from 'reactstrap'
import * as GlobalConstants from '../../constants'
import * as Utils from '../../generic/utility'
import * as Actions from './actions'
import './styles.css'

const {Button, Col, Form, FormFeedback, Row} = ReactStrap

interface ServerError {
  error: boolean,
  message: string,
}

interface ResetPasswordProps {
  code: string,
  email: string,
  isLoading: boolean,
  serverError?: ServerError,
}

interface MapDispatchToProps {
  confirmPassword: any,
  setIsLoading: any,
}

type Props = MapDispatchToProps & ResetPasswordProps

interface State {
  code: string,
  confirm_password: string,
  email: string,
  password: string,
  validConfirmPassword: boolean,
  invalidPasswordMessage: string,
}

class ResetPasswordComponentClass extends Component<Props, State> {
  constructor(props: Props) {
    super(props, '');
    this.state = {
      code: '',
      confirm_password: '',
      email: '',
      password: '',
      validConfirmPassword: true,
      invalidPasswordMessage: '',
    }
  }

  public render() {
    return (
      <div className="reset-password">
        <div className="dashboard-menu">
          <div className={'dashboard-menu-item dashboard-rasa-logo'}>
            <RasaLogo/>
          </div>
          <div className="dashboard-popup-menu-item clickable-item need-a-help">
            <a href={GlobalConstants.RASA_HELP_BASE_URL} target="_blank" rel="noopener">Help & Support</a>
          </div>
        </div>
        <Row className="reset-wrapper">
          <Col className="content">
            <h1>Reset Password</h1>
            <div className="breakspace"></div>
            <Form onSubmit={preventDefaultThen(() => {
              this.props.setIsLoading(true)
              this.props.confirmPassword({
                code: this.props.code,
                email: this.props.email,
                new_password: this.state.password,
              })})}>
              <ReactStrap.FormGroup className="reset-input">
                <ReactStrap.Label className="input-label" for="email">Email</ReactStrap.Label>
                <Input
                  className="input-text"
                  type="text"
                  name="email"
                  readOnly={true}
                  value={this.props.email || ''}
                />
              </ReactStrap.FormGroup>
              <ReactStrap.FormGroup className="reset-input">
                <ReactStrap.Label className="input-label" for="password">Password</ReactStrap.Label>
                <Input
                  className={'input-password'}
                  id="hidden-password"
                  type="password"
                  name="password"
                  label="Password"
                  onChange={(e) => {
                    const pwd = e.target.value.trim()
                    this.updateField({name: 'password', value: pwd})
                    this.setState({invalidPasswordMessage: Utils.validatePassword(pwd)})
                    this.setState({validConfirmPassword: (pwd === this.state.confirm_password)})
                  }}
                  placeholder="12+ Characters + Uppercase + Lowercase & Number" />
                  {this.state.invalidPasswordMessage &&
                    <FormFeedback className="d-block password-error">{this.state.invalidPasswordMessage}</FormFeedback>}
              </ReactStrap.FormGroup>
              <ReactStrap.FormGroup className="reset-input">
                <ReactStrap.Label className="input-label" for="password">Confirm Password</ReactStrap.Label>
                <Input
                  className={'input-password'}
                  type="password"
                  name="password"
                  label="Password"
                  onChange={(e) => {
                    const pwd = e.target.value.trim()
                    this.updateField({name: 'confirm_password', value: pwd})
                    this.setState({validConfirmPassword: (this.state.password === pwd)})
                  }}
                  placeholder="re-enter password" />
                  {this.state.confirm_password && !this.state.validConfirmPassword && <FormFeedback className="d-block password-error">Passwords must match.</FormFeedback>}
              </ReactStrap.FormGroup>
              {this.props.serverError.message ?
                <div>
                  <div className="invalid-credentials">
                    {this.props.serverError.message}
                  </div>
                  <a className="forgot-password" href="/forgot-password">Generate a new link</a>
                </div>
                : null}
              {this.props.isLoading && <Loading size="32"></Loading>}
              <Button className="btn-submit"
                disabled={!this.state.password.length
                  || !this.props.code.length
                  || this.state.invalidPasswordMessage.length > 0
                  || !this.state.validConfirmPassword
                  || this.props.isLoading}
              >Submit</Button>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }

  private updateField(event: any) {
    this.setState({
      ...this.state,
      [event.name]: event.value,
    })
  }
}

export const ResetPasswordComponent = connect(
  ({resetPassword}: any) => ({
    code: resetPassword.code,
    email: resetPassword.email,
    isLoading: resetPassword.isLoading,
    serverError: resetPassword.serverError,
  }),
  {
    confirmPassword: Actions.submitConfirmPassword,
    setIsLoading: Actions.setIsLoading,
  },
)(ResetPasswordComponentClass)
