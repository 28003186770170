import { AjaxWrapper } from 'generic/ajaxWrapper'
import get from 'lodash/get'
import { parse } from 'query-string'
import * as ReduxObservable from 'redux-observable'
import * as Request from 'request'
import * as Router from 'router'
import {filter, map, mergeMap, tap} from 'rxjs/operators'
import { SELECTED_COMMUNITY } from 'store'
import * as AuthConstants from '../../auth/constants'
import * as Errors from '../../shared_server_client/types/error'
import * as LoginConstants from '../login/constants'
import * as Actions from './actions'
import * as Constants from './constants'

const onEnter: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Router.ON_ENTER)
    .pipe(
      filter(() => history.location.pathname === Constants.SIGNUP_BASE_ROUTE),
      map(() => parse(history.location.search)),
      filter(({c, t}) => !!c && !!t),
      mergeMap(({c, t}) => [
        Actions.setToken(t.toString()),
        Actions.setCommunity(c.toString()),
        Actions.getInvitationInfo({community: c.toString(), invitation_token: t.toString()}),
        Actions.getSSOClients(),
      ]),
    )

const initializeSSO: ReduxObservable.Epic = (action$, state$, {}) =>
  action$.ofType(Request.successConstant(LoginConstants.SSO_CLIENTS))
    .pipe(
      tap((action) => {
        window.localStorage.setItem('googleClientId', action.payload.response.googleClientId)
      }),
      mergeMap((action) => [
        Actions.setFacebookAppId(action.payload.response.facebookAppId),
        ({type: 'SSO_CLIENTS_LOADED'}),
      ]),
    )

const signupSuccess: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.successConstant(Constants.SUBMIT_SIGNUP))
    .pipe(
      tap((signupResponse) => window.localStorage.setItem(AuthConstants.RASA_AUTH_TOKEN,
        signupResponse.payload.response.token)),
      tap((signupResponse) => window.localStorage.setItem(SELECTED_COMMUNITY, state$.value.signup.community)),
      tap((signupResponse) => AjaxWrapper.setToken(signupResponse.payload.response.token)),
      map(() => Router.redirect('/')), // TODO: send user to last location
    )

const signupFailed: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.errorConstant(Constants.SUBMIT_SIGNUP))
    .pipe(
      mergeMap((action) => [
        Actions.setIsError(true),
        Actions.setErrorMessage(Errors.getErrorMessage(get(action, 'payload.error.response.message'))),
        Actions.setIsLoading(false),
      ]),
    )

const userInfoSuccess: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.successConstant(Constants.INVITATION_USER_INFO))
    .pipe(
      mergeMap((action) => [
        Actions.setCommunityName(action.payload.response.community_name),
        Actions.setEmail(action.payload.response.email),
      ]),
    )

const userInfoFailed: ReduxObservable.Epic = (action$, state$, {window}) =>
action$.ofType(Request.errorConstant(Constants.INVITATION_USER_INFO))
.pipe(
  mergeMap((action) => [
    Actions.setIsError(true),
    Actions.setErrorMessage(Errors.getErrorMessage(get(action, 'payload.error.response.message'))),
  ],
))

export const epic = ReduxObservable.combineEpics(onEnter,
  initializeSSO, signupFailed, signupSuccess, userInfoFailed, userInfoSuccess)
