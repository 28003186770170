import React from 'react'
import {TwoFactorAuthComponent} from './component'
import * as Constants from './constants'

export const routes = [
  {
    action: () => <TwoFactorAuthComponent />,
    path: Constants.FA_BASE_ROUTE,
  },
]
