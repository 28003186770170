import * as ReduxObservable from 'redux-observable'
import * as Router from 'router'
import {filter, map} from 'rxjs/operators'

const getExportParam = (history) => {
  const qs = history.location.search
  const p = new URLSearchParams(qs)
  return p.get('_export')
}

export const onEnter: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Router.ON_ENTER)
    .pipe(
      filter(() => history.location.pathname === '/analytics/topics' && !!getExportParam(history)),
      map(() => {
        const url = decodeURI(getExportParam(history))
        return Router.redirect(url)
      }),
    )

export const epic = ReduxObservable.combineEpics(onEnter)
