import { ContentLibraryComponent } from 'components/content-pool/library/library'
import { ContentPoolSourceDetails } from 'components/content-pool/library/sourceDetails'
import { ContentLibrarySourcesComponent } from 'components/content-pool/library/sources'
import { Books } from 'components/icons/books'
import {PanelBarOption} from 'components/panel-bar/types'
import * as React from 'react'

const contentLibrary = <Books svgwidth={34.7} svgheight={32.9}/>

export const sections: PanelBarOption[] = [
  {
    category: '',
    component: ContentLibraryComponent,
    icon: contentLibrary,
    name: 'Content Library',
    route: 'library',
  },
  {
    category: '',
    component: ContentLibrarySourcesComponent,
    icon: null,
    name: 'Add Sources',
    route: 'sources',
  },
  {
    category: '',
    component: ContentPoolSourceDetails,
    hide: true,
    name: 'Source Details',
    route: 'library/source',
  },
]
