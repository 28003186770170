import { Banner } from 'components/banner/component'
import {AppleIcon} from 'components/icons/apple'
import {FacebookIcon} from 'components/icons/facebook'
import {GoogleIcon} from 'components/icons/google'
import {RasaLogo} from 'components/icons/rasalogo'
import {Input} from 'components/input/component'
import {preventDefaultThen} from 'components/link/component'
import { Loading } from 'components/loading'
import React, {Component} from 'react'
import { connect } from 'react-redux'
import * as ReactStrap from 'reactstrap'
import * as Router from 'router'
import * as Errors from 'shared_server_client/types/error'
import * as AuthConstants from '../../auth/constants'
import * as GlobalConstants from '../../constants'
import * as Utils from '../../generic/utility'
import { getRecaptchaToken, loadRecaptcha } from '../../recaptcha'
import * as Actions from './actions'
import * as Constants from './constants'
import './styles.css'

declare const google: any
declare const AppleID: any
declare const FB: any
const {Button, Col, Form, Row} = ReactStrap

interface LoginState {
  facebookAppId: string,
  loginFailedState: boolean,
  loginFailedMessage: string,
  loginInProgressState: boolean,
}

interface MapDispatchToProps {
  submitLogin: any,
  linkedInLogin: any,
  loginFailed: any,
  loginInProgress: any,
  push: any,
}

type Props = MapDispatchToProps & LoginState

interface State {
  email: string,
  loginInProgress: boolean,
  password: string,
}

export class LoginComponentClass extends Component<Props, State> {
  constructor(props: Props) {
    super(props, '');
    this.state = {
      email: '',
      loginInProgress: false,
      password: '',
    }
  }

  public componentDidMount() {
    loadRecaptcha()
  }

  public render() {
    return (
      <div className="login-page">
        <Banner/>
        <div className="dashboard-menu">
          <div className={'dashboard-rasa-logo'}>
            <RasaLogo/>
          </div>
          <div className="dashboard-popup-menu-item clickable-item need-a-help">
            <a href={GlobalConstants.RASA_HELP_BASE_URL} target="_blank" rel="noopener">Help & Support</a>
          </div>
        </div>
        <Row className="login-wrapper">
          <Col className="login-left-col">
            <div>
              <h1>Login</h1>
              <p className="enter-paragraph">Welcome back!</p>
              {/* <p className="sign-in-with-options">You can also login with these:</p> */}
              <Button className="signinwith google"
                onClick={() => {
                  this.googleLogin()
                }}
                disabled={this.props.loginInProgressState}
                ><GoogleIcon /><span className="external-login-text">Login with Google</span>
              </Button>
              { Utils.isFacebookLoaded() &&
              <Button className="signinwith facebook"
                onClick={() => {
                  this.props.loginInProgress(true)
                  this.props.loginFailed(false)
                  try {
                    FB.init({
                      appId      : this.props.facebookAppId,
                      cookie     : true,
                      xfbml      : true,
                      version    : 'v5.0',
                    })
                    FB.getLoginStatus((fbLoginStatusRes) => {
                      if (fbLoginStatusRes.authResponse && fbLoginStatusRes.status === 'connected') {
                        // user already logged in
                        FB.api('/me/permissions', (permissionResponse) => {
                          // does user has granted email permission?
                          const emailPermission = permissionResponse.data.filter((p) =>
                            p.permission === 'email' && p.status === 'granted')
                          if (emailPermission.length) {
                            // user has granted email permission
                            getRecaptchaToken('facebooklogin').then((token) => {
                              this.props.submitLogin({
                                identity_provider: Constants.FACEBOOK_IDENTITY_PROVIDER,
                                idp_code: fbLoginStatusRes.authResponse.accessToken,
                                recaptcha: token,
                              })
                            })
                          } else {
                            // user hasn't granted email permission
                            // eslint-disable-next-line no-console
                            console.log('No email permission. Current Facebook Permissions: ', permissionResponse.data)
                            FB.login((fbRes) => {
                              if (fbRes.authResponse && fbRes.authResponse.accessToken) {
                                getRecaptchaToken('facebooklogin').then((token) => {
                                  this.props.submitLogin({
                                    identity_provider: Constants.FACEBOOK_IDENTITY_PROVIDER,
                                    idp_code: fbRes.authResponse.accessToken,
                                    recaptcha: token,
                                  })
                                })
                              } else {
                                // eslint-disable-next-line no-console
                                console.log('Facebook login cancelled')
                                this.props.loginInProgress(false)
                              }
                            },
                            {
                              return_scopes: true,
                              scope: 'email',
                            })
                          }
                        })
                      } else {
                        FB.login((fbRes) => {
                          if (fbRes.authResponse && fbRes.authResponse.accessToken) {
                            getRecaptchaToken('facebooklogin').then((token) => {
                              this.props.submitLogin({
                                identity_provider: Constants.FACEBOOK_IDENTITY_PROVIDER,
                                idp_code: fbRes.authResponse.accessToken,
                                recaptcha: token,
                              })
                            })
                          } else {
                            // eslint-disable-next-line no-console
                            console.log('Facebook login cancelled')
                            this.props.loginInProgress(false)
                          }
                        },
                        {
                          return_scopes: true,
                          scope: 'email',
                        })
                      }
                    })
                  } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log('Facebook login error: ', err)
                    this.props.loginInProgress(false)
                  }
                }}
                disabled={this.props.loginInProgressState}
                ><FacebookIcon svgwidth={16.48} svgheight={16.48}/>
                <span className="external-login-text">Login with Facebook</span>
              </Button>
              }
              { Utils.isAppleLoaded() &&
              <Button className="signinwith apple"
                onClick={() => {
                  this.props.loginInProgress(true)
                  this.props.loginFailed(false)
                  try {
                    AppleID.auth.init({
                      clientId   : Constants.APPLE_CLIENT_ID,
                      scope : Constants.APPLE_AUTH_SCOPE,
                      redirectURI: window.location.origin + window.location.pathname,
                      state : Constants.APPLE_AUTH_STATE,
                      usePopup: true,
                    })
                    AppleID.auth.signIn()
                    .then((appleRes) => {
                      getRecaptchaToken('applelogin').then((token) => {
                        this.props.submitLogin({
                          identity_provider: Constants.APPLE_IDENTITY_PROVIDER,
                          id_token: appleRes.authorization.id_token,
                          recaptcha: token,
                        })
                      })
                    })
                    .catch((err) => {
                      // eslint-disable-next-line no-console
                      console.log('Apple login error - inner catch: ', err)
                      this.props.loginInProgress(false)
                    })
                  } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log('Apple login error - outer catch: ', err)
                    this.props.loginInProgress(false)
                  }
                }}
                disabled={this.props.loginInProgressState}
                ><AppleIcon svgwidth={16.48} svgheight={16.48}/>
                <span className="external-login-text">Login with Apple</span>
              </Button>
              }
            </div>
          </Col>
          <Col className="login-right-col">
            <div>
              <div className="emailsignintext">Login with email</div>
              <div className="breakspace"></div>
              <Form onSubmit={preventDefaultThen(() => this.cognitoLogin())}>
                <ReactStrap.FormGroup className="login-input">
                  <ReactStrap.Label className="input-label" for="email">Email</ReactStrap.Label>
                  <Input
                    className="input-text email-input"
                    type="text"
                    name="email"
                    onChange={(e) => this.updateField({name: 'email', value: e.target.value})}
                    invalid={this.state.email.length > 0 && !Utils.validateEmail(this.state.email)}
                    placeholder="johndoe@gmail.com" />
                  </ReactStrap.FormGroup>
                  <ReactStrap.FormGroup className="login-input">
                  <ReactStrap.Label className="input-label" for="password">Password</ReactStrap.Label>
                  <Input
                    className="input-password"
                    id="hidden-password"
                    type="password"
                    name="password"
                    label="Password"
                    onChange={(e) => this.updateField({name: 'password', value: e.target.value})}
                    placeholder="password"
                  />
                </ReactStrap.FormGroup>
                {this.props.loginFailedState && <div className="invalid-credentials">
                  {this.loginErrorMessage()}
                </div>}
                {this.props.loginInProgressState && <Loading size="64"></Loading>}
                <Button className="btn-submit"
                  disabled={!this.state.email.length  || !this.state.password.length || this.props.loginInProgressState}
                  >Login
                </Button>
                <div>
                  <a className="forgot-password" href="/forgot-password">Forgot Password?</a>
                </div>
                <div>
                  <a className="signup-link" href={Constants.signupUrl()}>Don't have an account? Create one now!</a>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  private googleLogin() {
    this.props.loginInProgress(true)
    this.props.loginFailed(false)

    const googleClient = google.accounts.oauth2.initCodeClient({
      client_id: window.localStorage.getItem('googleClientId'),
      scope: Constants.GOOGLE_AUTH_SCOPE,
      ux_mode: 'popup',
      callback: (response) => {
        getRecaptchaToken('googlesignup').then((token) => {
          this.props.loginInProgress(true)
          if (response.code) {
            this.props.submitLogin({
              identity_provider: Constants.GOOGLE_IDENTITY_PROVIDER,
              idp_code: response.code,
              recaptcha: token,
            })
          } else {
            this.props.loginInProgress(false)
          }
        })
      },
      error_callback: (error) => {
        this.props.loginInProgress(false)
      },
    })
    googleClient.requestCode()
  }

  private cognitoLogin() {
    this.props.loginInProgress(true)
    this.props.loginFailed(false)
    getRecaptchaToken('cognitologin')
      .then((token: string) => {
        this.props.submitLogin({
          email: this.state.email,
          identity_provider: Constants.COGNITO_IDENTITY_PROVIDER,
          fa_token: window.localStorage.getItem(AuthConstants.TWO_FACTOR_AUTH_TOKEN),
          password: this.state.password,
          recaptcha: token,
        })
      })
  }

  private updateField(event: any) {
    this.setState({
      ...this.state,
      [event.name]: event.value,
    })
  }

  private loginErrorMessage(): string {
    if ( this.props.loginFailedState ) {
      return Errors.getErrorMessage(this.props.loginFailedMessage) || 'Sorry, that is not the correct password'
    } else {
      return ''
    }
  }
}

export const LoginComponent = connect(
  ({login}: any) => ({
    facebookAppId: login.facebookAppId,
    loginFailedState: login.loginFailed,
    loginFailedMessage: login.loginFailedMessage,
    loginInProgressState: login.loginInProgress,
  }),
  {
    submitLogin: Actions.submitLogin,
    linkedInLogin: Actions.linkedInLogin,
    loginFailed: Actions.loginFailed,
    loginInProgress: Actions.loginInProgress,
    googleLoginCallback: Actions.googleLoginCallback,
    push: Router.push,
  },
)(LoginComponentClass)
