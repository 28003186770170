import React from 'react'
import {IntegrationsCallbackComponent} from './component'
import {INTEGRATIONS_CALLBACK_BASE_ROUTE} from './constants'

export const routes = [
  {
    action: () => <IntegrationsCallbackComponent />,
    path: INTEGRATIONS_CALLBACK_BASE_ROUTE,
  },
]
