import {DashboardMenuOption} from 'components/dashboard-menu/component'
import {PanelBarOption} from 'components/panel-bar/types'
import React from 'react'
import {BaseComponent} from './component'
import {allSections, mobileSections} from './constants'
import { MobileComponent } from './mobile-component'

export const routes = allSections.map( (item: PanelBarOption) => (
  {
    action: (action) => action.store.getState().config.isDesktop
    ? <BaseComponent selectedItem={item} />
    : <MobileComponent selectedItem={mobileSections.filter((x) => x.route === item.route)[0] || item} />,
    path: DashboardMenuOption.contacts + (item.route.length === 0 ? '' : '/' + item.route),
  }
));
