import { INTEGRATIONS_CONFIG_URL } from 'components/settings-editor/constants'
import * as ReduxObservable from 'redux-observable'
import * as Router from 'router'
import { filter, map, tap} from 'rxjs/operators'
import * as Constants from './constants'

export const onEnter: ReduxObservable.Epic = (action$, state$, {history, w}) =>
  action$.ofType(Router.ON_ENTER)
    .pipe(
      filter(() => history.location.pathname === Constants.INTEGRATIONS_CALLBACK_BASE_ROUTE),
      tap(() => {
        const queryParams: URLSearchParams = new URLSearchParams(history.location.search)
        let result = 'Success'
        if (queryParams.get('success') === 'false') {
          result = 'Failure'
        }
        if (window.opener) {
          let href: string
          if (queryParams.get('success') === 'false' && queryParams.get('operation') === 'Create') {
            href = INTEGRATIONS_CONFIG_URL + '?systemName=' + queryParams.get('systemName')
              + '&operation=' + queryParams.get('operation') + result
          } else {
            href = INTEGRATIONS_CONFIG_URL + '?id=' + queryParams.get('communitySystemId')
              + '&operation=' + queryParams.get('operation') + result
          }
          if (queryParams.get('message')) {
            href += `&message=${queryParams.get('message')}`
          }

          if (queryParams.get('ref')) {
            href += `&ref=${queryParams.get('ref')}`
          }

          if (queryParams.get('isRichIntegration')) {
            href += `&isRichIntegration=${queryParams.get('isRichIntegration')}`
          }

          window.opener.location.href = href
          window.close() // close the popup
        }
      }),
      map(() => ({type: 'INTEGRATIONS_CALLBACK_IGNORED'})),
    )

export const epic = ReduxObservable.combineEpics(onEnter)
