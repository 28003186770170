import * as Redux from 'redux'
import * as LoginConstants from '../login/constants'
import * as Constants from './constants'

export const reducer = Redux.combineReducers({
  community: (state = null, {type, community}) => type === Constants.SET_COMMUNITY ? community : state,
  communityName: (state = null, {type, communityName}) => type === Constants.SET_COMMUNITY_NAME ? communityName : state,
  email: (state = null, {type, email}) => type === Constants.SET_EMAIL ? email : state,
  errorMessage: (state = null, {type, message}) => type === Constants.SIGNUP_ERROR_MESSAGE ? message : state,
  facebookAppId: (state = null, {type, value}) => type === LoginConstants.SET_FACEBOOK_APP_ID ? value : state,
  isError: (state = false, {type, isError}) => type === Constants.IS_SIGNUP_ERROR ? isError : state,
  isLoading: (state = false, {type, isLoading}) => type === Constants.SET_IS_LOADING ? isLoading : state,
  token: (state = null, {type, token}) => type === Constants.SET_TOKEN ? token : state,
})
