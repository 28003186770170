import { Banner } from 'components/banner/component'
import { AppleIcon } from 'components/icons/apple'
import { FacebookIcon } from 'components/icons/facebook'
import {GoogleIcon} from 'components/icons/google'
import {RasaLogo} from 'components/icons/rasalogo'
import {Input} from 'components/input/component'
import {preventDefaultThen} from 'components/link/component'
import { Loading } from 'components/loading'
import React, {useState} from 'react'
import { connect } from 'react-redux'
import * as ReactStrap from 'reactstrap'
import { getRecaptchaToken, loadRecaptcha } from 'recaptcha'
import * as Router from 'router'
import * as Utils from '../../../generic/utility'
import * as Actions from '../actions'
import * as Constants from '../constants'
import './_styles.scss'

declare const google: any
declare const AppleID: any
declare const FB: any
const {Button, Form} = ReactStrap

const MobileLoginComponent = (props) => {
  loadRecaptcha()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  return <div className="mobile login-page">
    <Banner/>
    <div className={'dashboard-rasa-logo'}>
      <RasaLogo/>
    </div>
    <div className="wrapper">
      <h1>Login</h1>
      <p className="enter-paragraph">Welcome back!</p>
      <p className="enter-paragraph">Login with</p>
      <Button className="signinwith google"
        onClick={() => {
          googleLogin(props)
        }}
        disabled={props.loginInProgressState}
        >
        <GoogleIcon />
      </Button>
      { Utils.isFacebookLoaded() &&
        <Button className="signinwith facebook"
          onClick={() => {
            props.loginInProgress(true)
            props.loginFailed(false)
            try {
              FB.init({
                appId      : props.facebookAppId,
                cookie     : true,
                xfbml      : true,
                version    : 'v5.0',
              })
              FB.getLoginStatus((fbLoginStatusRes) => {
                if (fbLoginStatusRes.authResponse && fbLoginStatusRes.status === 'connected') {
                  // user already logged in
                  FB.api('/me/permissions', (permissionResponse) => {
                    // does user has granted email permission?
                    const emailPermission = permissionResponse.data.filter((p) =>
                      p.permission === 'email' && p.status === 'granted')
                    if (emailPermission.length) {
                      // user has granted email permission
                      props.submitLogin({
                        identity_provider: Constants.FACEBOOK_IDENTITY_PROVIDER,
                        idp_code: fbLoginStatusRes.authResponse.accessToken,
                      })
                    } else {
                      // user hasn't granted email permission
                      // eslint-disable-next-line no-console
                      console.log('No email permission. Current Facebook Permissions: ', permissionResponse.data)
                      FB.login((fbRes) => {
                        if (fbRes.authResponse && fbRes.authResponse.accessToken) {
                          props.submitLogin({
                            identity_provider: Constants.FACEBOOK_IDENTITY_PROVIDER,
                            idp_code: fbRes.authResponse.accessToken,
                          })
                        } else {
                          // eslint-disable-next-line no-console
                          console.log('Facebook login cancelled')
                          props.loginInProgress(false)
                        }
                      },
                      {
                        return_scopes: true,
                        scope: 'email',
                      })
                    }
                  })
                } else {
                  FB.login((fbRes) => {
                    if (fbRes.authResponse && fbRes.authResponse.accessToken) {
                      props.submitLogin({
                        identity_provider: Constants.FACEBOOK_IDENTITY_PROVIDER,
                        idp_code: fbRes.authResponse.accessToken,
                      })
                    } else {
                      // console.log('Facebook login cancelled')
                      props.loginInProgress(false)
                    }
                  },
                  {
                    return_scopes: true,
                    scope: 'email',
                  })
                }
              })
            } catch (err) {
              // console.log('Facebook login error: ', err)
              props.loginInProgress(false)
            }
          }}
          disabled={props.loginInProgressState}
          ><FacebookIcon svgwidth={16.48} svgheight={16.48}/>
        </Button>
      }
      { Utils.isAppleLoaded() &&
        <Button className="signinwith apple"
          onClick={() => {
            props.loginInProgress(true)
            props.loginFailed(false)
            try {
              AppleID.auth.init({
                clientId   : Constants.APPLE_CLIENT_ID,
                scope : Constants.APPLE_AUTH_SCOPE,
                redirectURI: window.location.origin + window.location.pathname,
                state : Constants.APPLE_AUTH_STATE,
                usePopup: true,
              })
              AppleID.auth.signIn()
              .then((appleRes) => {
                props.submitLogin({
                  identity_provider: Constants.APPLE_IDENTITY_PROVIDER,
                  id_token: appleRes.authorization.id_token,
                })
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                console.log('Apple login error - inner catch: ', err)
                props.loginInProgress(false)
              })
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log('Apple login error - outer catch: ', err)
              props.loginInProgress(false)
            }
          }}
          disabled={props.loginInProgressState}
          ><AppleIcon svgwidth={16.48} svgheight={16.48}/>
        </Button>
      }
      <p className="emailsignintext">or use your email</p>
      <Form onSubmit={preventDefaultThen(() => {
        props.loginInProgress(true)
        props.loginFailed(false)
        props.submitLogin({email,
          identity_provider: Constants.COGNITO_IDENTITY_PROVIDER,
          password})})}>
        <ReactStrap.FormGroup className="login-input">
          <ReactStrap.Label className="input-label" for="email">Email</ReactStrap.Label>
          <Input
            className="input-text"
            type="text"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            invalid={email.length > 0 && !Utils.validateEmail(email)}
            placeholder="johndoe@gmail.com" />
          </ReactStrap.FormGroup>
          <ReactStrap.FormGroup className="login-input">
          <ReactStrap.Label className="input-label" for="password">Password</ReactStrap.Label>
          <Input
            className="input-password"
            id="hidden-password"
            type="password"
            name="password"
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
          />
        </ReactStrap.FormGroup>
        {props.loginFailedState && <div className="invalid-credentials">Invalid Credentials!</div>}
        {props.loginInProgressState && <Loading size="64"/>}
        <Button className="btn-submit"
          disabled={!email.length  || !password.length || props.loginInProgressState}
          >Login
        </Button>
        <div>
          <a className="forgot-password" href="/forgot-password">Forgot Password?</a>
        </div>
      </Form>
    </div>
  </div>
}

const googleLogin = (props) => {
  props.loginInProgress(true)
  props.loginFailed(false)

  const googleClient = google.accounts.oauth2.initCodeClient({
    client_id: window.localStorage.getItem('googleClientId'),
    scope: Constants.GOOGLE_AUTH_SCOPE,
    ux_mode: 'popup',
    callback: (response) => {
      getRecaptchaToken('googlesignup').then((token) => {
        props.loginInProgress(true)
        if (response.code) {
          props.submitLogin({
            identity_provider: Constants.GOOGLE_IDENTITY_PROVIDER,
            idp_code: response.code,
            recaptcha: token,
          })
        } else {
          // eslint-disable-next-line no-console
          console.log('no code ', response.code)
          props.loginInProgress(false)
        }
      })
    },
    error_callback: (error) => {
      props.loginInProgress(false)
    },
  })
  googleClient.requestCode()
}

export const MobileLogin = connect(
  ({login}: any) => ({
    facebookAppId: login.facebookAppId,
    loginFailedState: login.loginFailed,
    loginInProgressState: login.loginInProgress,
  }),
  {
    submitLogin: Actions.submitLogin,
    linkedInLogin: Actions.linkedInLogin,
    loginFailed: Actions.loginFailed,
    loginInProgress: Actions.loginInProgress,
    push: Router.push,
  },
)(MobileLoginComponent)
