import {DashboardMenuOption} from 'components/dashboard-menu/component'
import {PanelBarOption} from 'components/panel-bar/types'
import React from 'react'
import {AdminComponent} from './component'
import {sections} from './constants'

export const routes = sections.map( (item: PanelBarOption) => (
  {
    action: (action) => <AdminComponent selectedItem={item} />,
    path: DashboardMenuOption.admin + (item.route.length === 0 ? '' : '/' + item.route),
  }
));
