import { DashboardMenu, DashboardMenuOption } from 'components/dashboard-menu/component'
import { GenericNavigatorSelectedItemProps } from 'components/generic-navigator/component'
import { DesktopNavigator } from 'components/generic-navigator/desktop'
import * as Globals from 'generic/globals'
import React, {Component} from 'react'
import * as Constants from './constants'
import { SharedKeys, SharedStore } from '../../shared/data-layer/sharedStore'
import { Roles } from '../../shared/constants'
import { RasaContext } from '../../context'
import { Loading } from '../../components/loading';
import { RETRY_LIMIT, RETRY_WAIT_TIME } from './constants';
import * as Flash from '../../components/flash';

const headerImgElement = <img src={Globals.ADMIN_IMG} className="panel-generic-header-icon" />

interface CsDashboardComponentState {
  isLoading: boolean,
  isSuperUser: boolean,
  retryCount: number,
}
export class CSDashboardComponent extends Component<GenericNavigatorSelectedItemProps, CsDashboardComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: GenericNavigatorSelectedItemProps) {
    super(props)
    this.state = {
      isLoading: true,
      isSuperUser: false,
      retryCount: 1,
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    this.fetchActiveCommunity()
  }

  public render() {
    return (
      this.state.isLoading
        ? <Loading size="64"/>
        : <div>
          <DashboardMenu selected={DashboardMenuOption.csArticleActivity}/>
          <div>
            <DesktopNavigator
              selectedItem={this.props.selectedItem}
              sections={Constants.sections(this.state.isSuperUser)}
              baseRoute={DashboardMenuOption.csDashboard}
              title="CS Toolbox"
              subTitle="Insights"
              headerIcon={headerImgElement}>
            </DesktopNavigator>
          </div>
        </div>
    );
  }

  private fetchActiveCommunity = () => {
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      if (!activeCommunity || !activeCommunity.role) {
        if (this.state.retryCount <= RETRY_LIMIT) {
          setTimeout(() => {
            this.setState({
              retryCount: this.state.retryCount + 1,
            }, () => {
              this.fetchActiveCommunity()
            })
          }, RETRY_WAIT_TIME)
        } else {
          this.setState({
            isLoading: false,
          }, () => {
            this.context.store.dispatch(Flash.showFlashError(Constants.ACTIVE_COMMUNITY_NOT_FOUND_ERROR))
          })
        }
      } else {
        this.setState({
          isLoading: false,
          isSuperUser: activeCommunity.role === Roles.super_admin,
        })
      }
    })
  }
}
