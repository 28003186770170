import React from 'react'
import {ResetPasswordComponent} from './component'
import {RESET_PASSWORD_BASE_ROUTE} from './constants'

export const routes = [
  {
    action: () => <ResetPasswordComponent />,
    path: RESET_PASSWORD_BASE_ROUTE,
  },
]
