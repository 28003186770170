import React from 'react'
import {SignupComponent} from './component'
import { SIGNUP_BASE_ROUTE } from './constants'

export const routes = [
  {
    action: () => <SignupComponent />,
    path: SIGNUP_BASE_ROUTE,
  },
]
