import React from 'react'
import {SuccessPageComponent} from '../success/component'
import {ForgotPasswordComponent} from './component'
import * as Constants from './constants'

export const routes = [
  {
    action: () => <ForgotPasswordComponent />,
    path: Constants.FORGOT_PASSWORD_BASE_ROUTE,
  },
  {
    action: () => <SuccessPageComponent heading={Constants.FORGOT_PASSWORD_SUCCESS_HEADING}
                    content={Constants.FORGOT_PASSWORD_SUCCESS_CONTENT} />,
    path: Constants.FORGOT_PASSWORD_SUCCESS_ROUTE,
  },
]
