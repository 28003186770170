import {DashboardMenuOption} from 'components/dashboard-menu/component'
import {PanelBarOption} from 'components/panel-bar/types'
import React from 'react'
import {SettingsComponent} from './component'
import {allSections} from './constants'
import { MobileSettingsComponent } from './mobile-component'

export const routes = allSections.map( (item: PanelBarOption) => (
  {
    action: (action) => action.store.getState().config.isDesktop ?
    <SettingsComponent selectedItem={item}  />
    : <MobileSettingsComponent selectedItem={item} />,
    path: DashboardMenuOption.settings + (item.route.length === 0 ? '' : '/' + item.route),
  }
));
