import { Banner } from 'components/banner/component'
import { DashboardMenuOption } from 'components/dashboard-menu/component'
import { MobileDashboardMenu } from 'components/dashboard-menu/mobile/mobile-component'
import { GenericNavigatorSelectedItemProps } from 'components/generic-navigator/component'
import { MobileNavigator } from 'components/generic-navigator/mobile/mobile'
import React, { Component } from 'react'
import * as Constants from './constants'

export class MobileScheduleComponent extends Component<GenericNavigatorSelectedItemProps> {
  public render() {
    return (
      <div>
        <Banner/>
        <MobileDashboardMenu selected={DashboardMenuOption.schedule} />
        <div>
          <MobileNavigator
              selectedItem={this.props.selectedItem}
              sections={Constants.mobileSections}
              baseRoute={DashboardMenuOption.schedule}
              title="Schedule"
              subTitle="Manage your schedule here"
          />
        </div>
      </div>
    )
  }
}
