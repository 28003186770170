import * as Request from 'request'
import * as Constants from './constants'

export const setIsLoading = (isLoading: boolean) => ({type: Constants.FORGOT_PASSWORD_SET_IS_LOADING, isLoading})
export const setServerError = (serverError: boolean) =>
  ({type: Constants.FORGOT_PASSWORD_SET_SERVER_ERROR, serverError})

export const submitForgotPassword = (email: string) =>
Request.requestActionCreator(Constants.SUBMIT_FORGOT_PASSWORD)
({
  method: 'post',
  payload: {email},
  url: '/auth/forgot-password',
})
