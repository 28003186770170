import { AjaxWrapper } from 'generic/ajaxWrapper'
import { LOGIN_BASE_ROUTE } from 'pages/login/constants'
import * as ReduxObservable from 'redux-observable'
import * as Router from 'router'
import {ajax} from 'rxjs/ajax'
import {catchError, filter, map, mergeMap, switchMap, tap} from 'rxjs/operators'
import * as AuthConstants from '../../auth/constants'
import * as StoreConstants from '../../store/constants'
import * as Constants from './constants'

export const logout = () => {
  window.localStorage.removeItem(StoreConstants.SELECTED_COMMUNITY)
  window.localStorage.removeItem(AuthConstants.RASA_AUTH_TOKEN)
  AjaxWrapper.setToken(null)
}

export const onLogout: ReduxObservable.Epic = (action$, state$, {}) =>
  action$.ofType(Constants.HANDLE_LOGOUT)
    .pipe(
      tap((action) => logout()),
      mergeMap(() => [
        ({ type: AuthConstants.LOGGED_OUT }),
        Router.redirect(LOGIN_BASE_ROUTE),
      ]),
      catchError(() => {
        Router.redirect(LOGIN_BASE_ROUTE)
        return null
      }),
    )

export const onEnter: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Router.ON_ENTER)
    .pipe(
      filter(() => history.location.pathname === Constants.LOGOUT_BASE_ROUTE),
      map(() => window.localStorage.getItem(AuthConstants.RASA_AUTH_TOKEN)),
      switchMap((authToken) =>
        ajax({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
          },
          method: 'post',
          url: state$.value.config.api + '/auth/logout',
        }).pipe(
          map(() => ({ type: Constants.HANDLE_LOGOUT })),
        ),
      ),
    )

export const epic = ReduxObservable.combineEpics(onEnter, onLogout)
