import React from 'react'
import {RootComponent} from './component'
import { MobileRootComponent } from './mobile-component'

export const routes = [
  {
    action: (action) => action.store.getState().config.isDesktop
    ? <RootComponent/>
    : <MobileRootComponent/>,
    path: '/',
  },
]
