import { Banner } from 'components/banner/component'
import {DashboardMenuOption} from 'components/dashboard-menu/component'
import {GenericNavigatorSelectedItemProps} from 'components/generic-navigator/component'
import React, {Component} from 'react'
import * as Constants from './constants'

import { MobileDashboardMenu } from 'components/dashboard-menu/mobile/mobile-component'
import { MobileNavigator } from 'components/generic-navigator/mobile/mobile'
import { AccountAndBilling } from 'components/icons/account-and-billing'
import '../../components/icons/account-and-billing.css'

const billingIcon = <AccountAndBilling svgwidth={0} svgheight={0} style={{padding: '100px'}}/>

export class MobileSettingsComponent extends Component<GenericNavigatorSelectedItemProps> {
  public render() {
    return (
      <div>
        <Banner />
        <MobileDashboardMenu selected={DashboardMenuOption.settings} />
        <div>
          <MobileNavigator
              selectedItem={this.props.selectedItem}
              sections={Constants.mobileSection}
              baseRoute={DashboardMenuOption.settings}
              title="Account & Billing"
              subTitle="Manage your settings"
              headerIcon={billingIcon}/>
        </div>
      </div>
    );
  }
}
