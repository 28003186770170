import { Banner } from 'components/banner/component'
import { DashboardMenuOption } from 'components/dashboard-menu/component'
import { MobileDashboardMenu } from 'components/dashboard-menu/mobile/mobile-component'
import { GenericNavigatorSelectedItemProps } from 'components/generic-navigator/component'
import { MobileNavigator } from 'components/generic-navigator/mobile/mobile'
import * as Globals from 'generic/globals'
import React, {Component} from 'react'
import * as Constants from './constants'

const headerImgElement = <img src={Globals.CONTENT_POOL_IMG} className="panel-mobile-header-icon" />

export class MobileComponent extends Component<GenericNavigatorSelectedItemProps> {
  constructor(props: GenericNavigatorSelectedItemProps) {
    super(props)
  }

  public render() {
    return (
      <div>
        <Banner />
        <MobileDashboardMenu selected={DashboardMenuOption.content} />
        <div>
          <MobileNavigator
                selectedItem={this.props.selectedItem}
                sections={Constants.mobileSections}
                baseRoute={DashboardMenuOption.content}
                title="Content"
                subTitle=""
                headerIcon={headerImgElement}>
          </MobileNavigator>
        </div>
      </div>
    );
  }
}
