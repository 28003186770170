import { Banner } from 'components/banner/component'
import { DashboardMenuOption } from 'components/dashboard-menu/component'
import { MobileDashboardMenu } from 'components/dashboard-menu/mobile/mobile-component'
import { GenericNavigatorSelectedItemProps } from 'components/generic-navigator/component'
import { ReducedMobileNavigator } from 'components/generic-navigator/mobile/mobile'
import { AllContacts } from 'components/icons/allcontacts'
import React, {Component} from 'react'
import * as Constants from './constants'

const allcontacts = <AllContacts svgwidth={61.2} svgheight={60.5}/>

export class MobileComponent extends Component<GenericNavigatorSelectedItemProps> {
  constructor(props: GenericNavigatorSelectedItemProps) {
    super(props)
  }

  public render() {
    return (
      <div>
        <Banner/>
        <MobileDashboardMenu selected={DashboardMenuOption.contacts} />
        <div>
          <ReducedMobileNavigator
                selectedItem={this.props.selectedItem}
                sections={Constants.mobileSections}
                baseRoute={DashboardMenuOption.contacts}
                title="Contacts"
                subTitle="Manage your contacts here"
                headerIcon={allcontacts}>
          </ReducedMobileNavigator>
        </div>
      </div>
    );
  }
}
