import { DashboardMenu, DashboardMenuOption } from 'components/dashboard-menu/component'
import { EmailPreview } from 'components/email-layout/preview'
import React, { Component } from 'react'

export class PreviewComponent extends Component {
  public render() {
    return (
      <div>
        <DashboardMenu selected={DashboardMenuOption.preview} />
        <div>
            <EmailPreview />
        </div>
      </div>
    )
  }
}
