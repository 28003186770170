import {DashboardMenuOption} from 'components/dashboard-menu/component'
import {PanelBarOption} from 'components/panel-bar/types'
import React from 'react'
import {CSDashboardComponent} from './component'
import { allSections } from './constants'

export const routes = allSections.map( (item: PanelBarOption) => (
  {
    action: () => <CSDashboardComponent selectedItem={item} />,
    path: DashboardMenuOption.csDashboard + (item.route.length === 0 ? '' : '/' + item.route),
  }
));
