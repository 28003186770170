import * as Request from 'request'
import * as Constants from './constants'
import * as Types from './types'

export const setEmail = (email: string) => ({type: Constants.RESET_PASSWORD_SET_EMAIL, email})
export const setCode = (code: string) => ({type: Constants.RESET_PASSWORD_SET_CODE, code})
export const setIsLoading = (isLoading: boolean) => ({type: Constants.RESET_PASSWORD_SET_IS_LOADING, isLoading})
export const setResetPasswordServerError = (serverError: boolean, message: string) =>
  ({type: Constants.RESET_PASSWORD_SET_SERVER_ERROR, serverError, message})

export const submitConfirmPassword = (payload: Types.ResetPasswodPayload) =>
Request.requestActionCreator(Constants.SUBMIT_RESET_PASSWORD)
({
  method: 'post',
  payload,
  url: '/auth/confirm-password',
})
