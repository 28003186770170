import { EmailTemplateComponent } from 'components/email-layout/templates/components'

import { RexDemonstration } from 'components/rex/demonstration/component'
import { RexWebinar } from 'components/rex/webinar/component'

import { PanelBarOption } from 'components/panel-bar/types'
import { WidgetsChrome } from 'components/widgets/chrome-ext'
import { WidgetsRss } from 'components/widgets/rss'
import { WidgetsSignup } from 'components/widgets/signup/component'
import { WidgetsTopArticles } from 'components/widgets/top-articles/component'
import { WidgetsUnsubscribe } from 'components/widgets/unsubscribe/component'
import { WidgetsViewInBrowser } from 'components/widgets/viewInBrowser'

const forms  = 'Forms'
const tools =  'Tools'
export const allSections: PanelBarOption[] = [
  {
    category: forms,
    component: WidgetsSignup,
    isMenu: true,
    name: 'Subscribe',
    route: '',
  },
  {
    category: forms,
    component: WidgetsUnsubscribe,
    isMenu: true,
    name: 'Unsubscribe',
    route: 'unsubscribe',
  },
  {
    category: forms,
    component: WidgetsTopArticles,
    isMenu: true,
    name: 'Top Articles Subscribe',
    route: 'toparticles',
  },
  {
    category: tools,
    component: WidgetsRss,
    isMenu: true,
    name: 'Top Articles RSS Feed',
    route: 'rss',
  },
  {
    category: tools,
    component: WidgetsViewInBrowser,
    isMenu: true,
    name: 'View In Browser',
    route: 'viewinbrowser',
  },
  {
    category: tools,
    component: WidgetsChrome,
    isMenu: true,
    name: 'Chrome extension',
    route: 'chrome-ext',
  },
  {
    category: tools,
    component: EmailTemplateComponent,
    isMenu: true,
    name: 'Custom Email Templates',
    route: 'templates',
  },
  {
    category: tools,
    component: RexDemonstration,
    isMenu: true,
    name: 'Rex Demonstration',
    route: 'rex',
  },
  {
    category: tools,
    component: RexWebinar,
    isMenu: true,
    name: 'Rex Webinar',
    route: 'rex/webinar',
  },
]

export const sections = (isUserAdmin: boolean) => {
  const availableSections = allSections.filter((item) => {
    if (item.name === 'Template Codes') {
      return isUserAdmin
    } else if (item.name === 'Rex Demonstration') {
      return isUserAdmin
    } else if (item.name === 'Rex Webinar') {
      return isUserAdmin
    } else {
      return true
    }
  })
  return availableSections
}
