export const SIGNUP_BASE_ROUTE = '/signup'

export const SUBMIT_SIGNUP = 'SUBMIT_SIGNUP'
export const INVITATION_USER_INFO = 'INVITATION_USER_INFO'

export const SET_EMAIL = 'SET_EMAIL'
export const SET_IS_LOADING = 'IS_LOADING'
export const SET_COMMUNITY = 'SET_COMMUNITY'
export const SET_COMMUNITY_NAME = 'SET_COMMUNITY_NAME'
export const SET_TOKEN = 'SET_TOKEN'
export const COGNITO_SIGNUP = 'COGNITO_SIGNUP'
export const GOOGLE_SIGNUP = 'GOOGLE_SIGNUP'
export const LINKEDIN_SIGNUP = 'LINKEDIN_SIGNUP'
export const IS_SIGNUP_ERROR = 'IS_SIGNUP_ERROR'
export const SIGNUP_ERROR_MESSAGE = 'SIGNUP_ERROR_MESSAGE'
