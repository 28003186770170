import {DashboardMenu, DashboardMenuOption} from 'components/dashboard-menu/component'
import { GenericNavigatorSelectedItemProps } from 'components/generic-navigator/component'
import { DesktopNavigator } from 'components/generic-navigator/desktop'
import { MarkettingToolsIcon } from 'components/icons/markettingtools' // need to change
import { RasaContext } from 'context'
import React, {Component} from 'react'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Constants from './constants'

const widgetsIcon = <MarkettingToolsIcon width={57.6} height={57.6}/>

interface WidgetsStates {
  isSuperUser?: boolean,
}

export class WidgetsComponent extends Component<GenericNavigatorSelectedItemProps, WidgetsStates> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: any) {
    super(props)
    this.state = {
      isSuperUser: false,
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.role),
    ]).then(([role]) => {
      this.setState({
        isSuperUser: role === Roles.super_admin,
      })
    })
  }
  public render() {
    return (
      <div>
        <DashboardMenu selected={DashboardMenuOption.widgets} />
        <div>
          <DesktopNavigator
                  selectedItem={this.props.selectedItem}
                  sections={Constants.sections(this.state.isSuperUser)}
                  baseRoute={DashboardMenuOption.widgets}
                  title="Widgets"
                  subTitle="Great tools to help you boost your subscriber list."
                  headerIcon= {widgetsIcon}>
          </DesktopNavigator>
        </div>
      </div>
    );
  }
}
