import { AjaxWrapper } from 'generic/ajaxWrapper'
import { parse } from 'query-string'
import * as ReduxObservable from 'redux-observable'
import * as Request from 'request'
import * as Router from 'router'
import {filter, map, mergeMap, tap} from 'rxjs/operators'
import * as AuthConstants from '../../auth/constants'
import * as Actions from './actions'
import * as Constants from './constants'

const onEnter: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Router.ON_ENTER)
    .pipe(
      filter(() => history.location.pathname === Constants.FA_BASE_ROUTE),
      map(() => parse(history.location.search)),
      mergeMap(({email}) => {
        if (email) {
          return [
            Actions.setEmail(email.toString()),
          ]
        } else {
          return [Router.push('/login')]
        }
      }),
    )

export const twoFactorConfirmationSuccess: ReduxObservable.Epic = (action$, state$) =>
  action$.ofType(Request.successConstant(Constants.SUBMIT_2FA))
    .pipe(
      filter((faResponse) => faResponse.payload.response.fa_token),
      tap((faResponse) => window.localStorage.setItem(AuthConstants.RASA_AUTH_TOKEN,
        faResponse.payload.response.token)),
      tap((faResponse) => window.localStorage.setItem(AuthConstants.TWO_FACTOR_AUTH_TOKEN,
        faResponse.payload.response.fa_token)),
      tap((loginResponse) => AjaxWrapper.setToken(loginResponse.payload.response.token)),
      map(() => Router.redirect('/')),
    )

export const resendCodeSuccess: ReduxObservable.Epic = (action$, state$) =>
  action$.ofType(Request.successConstant(Constants.RESEND_2FA))
    .pipe(
      mergeMap(() => [
        Actions.setIsLoading(false),
        Actions.setServerError(false),
        Actions.setResendSuccess(true),
      ]),
    )

const twoFactorConfirmationFailed: ReduxObservable.Epic = (action$, state$, {window}) =>
action$.ofType(Request.errorConstant(Constants.SUBMIT_2FA))
  .pipe(
    mergeMap(() => [
      Actions.setIsLoading(false),
      Actions.setServerError(true),
      Actions.setResendSuccess(false),
    ]),
  )

export const epic = ReduxObservable.combineEpics(onEnter,
  resendCodeSuccess, twoFactorConfirmationSuccess, twoFactorConfirmationFailed)
