import { AjaxWrapper } from 'generic/ajaxWrapper'
import { parse } from 'query-string'
import * as ReduxObservable from 'redux-observable'
import * as Request from 'request'
import * as Router from 'router'
import {filter, map, mergeMap, tap} from 'rxjs/operators'
import * as AuthConstants from '../../auth/constants'
import * as TwoFactorAuthConstants from '../2fa-auth/constants'
import * as Actions from './actions'
import * as Constants from './constants'

const onEnter: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Router.ON_ENTER)
    .pipe(
      filter(() => history.location.pathname === Constants.RESET_PASSWORD_BASE_ROUTE),
      map(() => parse(history.location.search)),
      mergeMap(({email, c}) => {
        if (email && c) {
          return [
            Actions.setEmail(email.toString()),
            Actions.setCode(c.toString()),
          ]
        } else {
          return [Router.push('/forgot-password')]
        }
      }),
    )
export const confirmPasswordSuccess: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.successConstant(Constants.SUBMIT_RESET_PASSWORD))
    .pipe(
      tap((loginResponse) => window.localStorage.setItem(AuthConstants.RASA_AUTH_TOKEN,
        loginResponse.payload.response.token)),
      tap((loginResponse) => AjaxWrapper.setToken(loginResponse.payload.response.token)),
      map(() => Router.redirect('/')),
    )

export const confirmPassword2faEnabledSuccess: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.successConstant(Constants.SUBMIT_RESET_PASSWORD))
  .pipe(
    filter((confirmPasswordResponse) => confirmPasswordResponse.payload.response.is2faRequired),
    map((loginResponse) => {
      return window.location.replace(`${TwoFactorAuthConstants.FA_BASE_ROUTE}?email=${loginResponse.payload.response.userEmail ?
        loginResponse.payload.response.userEmail : JSON.parse(loginResponse.payload.request.body).email}`)
    }),
  )

const submitPasswordReset: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.requestConstant(Constants.SUBMIT_RESET_PASSWORD))
    .pipe(
      mergeMap((action: any) => [
        Actions.setIsLoading(true),
        Actions.setResetPasswordServerError(false, ''),
      ]),
    )

const resetPasswordFailed: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.errorConstant(Constants.SUBMIT_RESET_PASSWORD))
    .pipe(
      mergeMap((action: any) => [
        Actions.setIsLoading(false),
        Actions.setResetPasswordServerError(true, action.payload.error.response.error.message),
      ]),
    )

export const epic = ReduxObservable.combineEpics(
  confirmPassword2faEnabledSuccess,
  confirmPasswordSuccess,
  onEnter,
  resetPasswordFailed,
  submitPasswordReset,
)
