import React from 'react'
import {SuccessPageComponent} from '../success/component'
import * as Constants from './constants'

export const routes = [
  {
    action: () => <SuccessPageComponent heading={Constants.GENERIC_ERROR_HEADING}
                    content={Constants.genericErrorContent()} />,
    path: Constants.GENERIC_ERROR_ROUTE,
  },
]
