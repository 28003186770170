import {AppleIcon} from 'components/icons/apple'
import {FacebookIcon} from 'components/icons/facebook'
import {GoogleIcon} from 'components/icons/google'
import {RasaLogo} from 'components/icons/rasalogo'
import {Input} from 'components/input/component'
import {preventDefaultThen} from 'components/link/component'
import { Loading } from 'components/loading'
import React, {Component} from 'react'
import { connect } from 'react-redux'
import * as ReactStrap from 'reactstrap'
import * as GlobalConstants from '../../constants'
import * as Utils from '../../generic/utility'
import * as LoginConstants from '../login/constants'
import * as Actions from './actions'
// import * as Reducers from './reducer'
import './styles.css'

const {Alert, Button, Col, Form, FormFeedback, Row} = ReactStrap

declare const google: any
declare const AppleID: any
declare const FB: any

interface SignupState {
  community: string,
  communityName: string,
  email: string,
  errorMessage: string,
  facebookAppId: string,
  isLoading: boolean,
  isError: boolean,
  token: string,
}

interface MapDispatchToProps {
  submitSignup: any,
  setIsLoading: any,
  setIsError: any,
  linkedInSignup: any,
}

type Props = MapDispatchToProps & SignupState

interface State {
  confirm_password: string,
  password: string,
  validConfirmPassword: boolean,
  validEmail: boolean,
  invalidPasswordMessage: string,
}

class SignupComponentClass extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      confirm_password: '',
      password: '',
      validConfirmPassword: true,
      validEmail: true,
      invalidPasswordMessage: '',
    }
  }

  public render() {
    return (
      <div className="signup">
        <div className="dashboard-menu">
          <div className={'dashboard-menu-item dashboard-rasa-logo'}>
            <RasaLogo/>
          </div>
          <div className="dashboard-popup-menu-item clickable-item need-a-help">
            <a href={GlobalConstants.RASA_HELP_BASE_URL} target="_blank" rel="noopener">Help & Support</a>
          </div>
        </div>
        <Row className="signup-wrapper">
          <Col className="signup-left-col">
            <div>
              <h1>Join the Team!</h1>
              <p className="enter-paragraph">
                You've been invited to work on {this.props.communityName}'s smart newsletter
              </p>
              <br/>
              {/* <p className="sign-in-with-options">You can also sign up with these:</p> */}
              <Button className="signinwith google" onClick={() => {
                this.googleSignup()
              }}>
                <GoogleIcon /><span className="external-login-text">Sign up with Google</span>
              </Button>
              { Utils.isFacebookLoaded() &&
              <Button className="signinwith facebook"
                onClick={() => {
                  this.props.setIsLoading(true)
                  try {
                    FB.init({
                      appId      : this.props.facebookAppId,
                      cookie     : true,
                      xfbml      : true,
                      version    : 'v5.0',
                    })
                    FB.getLoginStatus((fbLoginStatusRes) => {
                      if (fbLoginStatusRes.authResponse && fbLoginStatusRes.status === 'connected') {
                        // user already logged in
                        FB.api('/me/permissions', (permissionResponse) => {
                          // does user has granted email permission?
                          const emailPermission = permissionResponse.data.filter((p) =>
                            p.permission === 'email' && p.status === 'granted')
                          if (emailPermission.length) {
                            // user has granted email permission
                            this.props.submitSignup({
                              community: this.props.community,
                              identity_provider: LoginConstants.FACEBOOK_IDENTITY_PROVIDER,
                              idp_code: fbLoginStatusRes.authResponse.accessToken,
                              invitation_token: this.props.token,
                            })
                          } else {
                            // user hasn't granted email permission
                            // eslint-disable-next-line no-console
                            console.log('No email permission. Current Facebook Permissions: ', permissionResponse.data)
                            FB.login((fbRes) => {
                              if (fbRes.authResponse && fbRes.authResponse.accessToken) {
                                this.props.submitSignup({
                                  community: this.props.community,
                                  identity_provider: LoginConstants.FACEBOOK_IDENTITY_PROVIDER,
                                  idp_code: fbLoginStatusRes.authResponse.accessToken,
                                  invitation_token: this.props.token,
                                })
                              } else {
                                // eslint-disable-next-line no-console
                                console.log('Facebook login cancelled')
                                this.props.setIsLoading(false)
                              }
                            },
                            {
                              return_scopes: true,
                              scope: 'email',
                            })
                          }
                        })
                      } else {
                        FB.login((fbRes) => {
                          if (fbRes.authResponse && fbRes.authResponse.accessToken) {
                            this.props.submitSignup({
                              community: this.props.community,
                              identity_provider: LoginConstants.FACEBOOK_IDENTITY_PROVIDER,
                              idp_code: fbRes.authResponse.accessToken,
                              invitation_token: this.props.token,
                            })
                          } else {
                            // eslint-disable-next-line no-console
                            console.log('Facebook login cancelled')
                            this.props.setIsLoading(false)
                          }
                        },
                        {
                          return_scopes: true,
                          scope: 'email',
                        })
                      }
                    })
                  } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log('Facebook login error: ', err)
                    this.props.setIsLoading(false)
                  }
                }}
                disabled={this.props.isLoading}
                ><FacebookIcon svgwidth={16.48} svgheight={16.48}/>
                <span className="external-login-text">Sign up with Facebook</span>
              </Button>
              }
              { Utils.isAppleLoaded() &&
              <Button className="signinwith apple"
                onClick={() => {
                  this.props.setIsLoading(true)
                  try {
                    AppleID.auth.init({
                      clientId   : LoginConstants.APPLE_CLIENT_ID,
                      scope : LoginConstants.APPLE_AUTH_SCOPE,
                      redirectURI: window.location.origin + window.location.pathname,
                      state : LoginConstants.APPLE_AUTH_STATE,
                      usePopup: true,
                    })
                    AppleID.auth.signIn()
                    .then((appleRes) => {
                      this.props.submitSignup({
                        community: this.props.community,
                        identity_provider: LoginConstants.APPLE_IDENTITY_PROVIDER,
                        id_token: appleRes.authorization.id_token,
                        invitation_token: this.props.token,
                      })
                    })
                    .catch((err) => {
                      // eslint-disable-next-line no-console
                      console.log('Apple login error - inner catch: ', err)
                      this.props.setIsLoading(false)
                    })
                  } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log('Apple login error - outer catch: ', err)
                    this.props.setIsLoading(false)
                  }
                }}
                disabled={this.props.isLoading}
                ><AppleIcon svgwidth={16.48} svgheight={16.48}/>
                <span className="external-login-text">Sign up with Apple</span>
              </Button>
              }
            </div>
          </Col>
          <Col className="signup-right-col">
            <div>
              <div className="emailsignintext">Sign up with email</div>
              <div className="breakspace"></div>
              <Form onSubmit={preventDefaultThen(() => {
                this.props.setIsLoading(true)
                this.props.submitSignup({
                  community: this.props.community,
                  email: this.props.email,
                  identity_provider: LoginConstants.COGNITO_IDENTITY_PROVIDER,
                  invitation_token: this.props.token,
                  password: this.state.password})
                })}>
                <ReactStrap.FormGroup className="signup-input">
                  <ReactStrap.Label className="input-label" for="email">Email</ReactStrap.Label>
                  <Input
                    className="input-text"
                    disabled={true}
                    type="text"
                    name="email"
                    value={this.props.email || ''}/>
                </ReactStrap.FormGroup>
                <ReactStrap.FormGroup className="signup-input">
                  <ReactStrap.Label className="input-label" for="password">Password</ReactStrap.Label>
                  <Input
                    // className={`input-password ${this.state.validPassword ? '' : 'invalid'}`}
                    className={'input-password'}
                    id="hidden-password"
                    type="password"
                    name="password"
                    label="Password"
                    onChange={(e) => {
                      const pwd = e.target.value.trim()
                      this.updateField({name: 'password', value: pwd})
                      this.setState({invalidPasswordMessage: Utils.validatePassword(pwd)})
                      this.setState({validConfirmPassword: (pwd === this.state.confirm_password)})
                    }}
                    placeholder="12+ Characters + Uppercase + Lowercase & Number" />
                    {this.state.invalidPasswordMessage &&
                      <FormFeedback className="d-block password-error">{this.state.invalidPasswordMessage}
                      </FormFeedback>}
                </ReactStrap.FormGroup>
                <ReactStrap.FormGroup className="signup-input">
                  <ReactStrap.Label className="input-label" for="password">Confirm Password</ReactStrap.Label>
                  <Input
                    className={`input-password ${this.state.validConfirmPassword ? '' : 'invalid'}`}
                    type="password"
                    name="password"
                    label="Password"
                    onChange={(e) => {
                      const pwd = e.target.value.trim()
                      this.updateField({name: 'confirm_password', value: pwd})
                      this.setState({validConfirmPassword: (this.state.password === pwd)})
                    }}
                    placeholder="re-enter password" />
                    {this.state.confirm_password && !this.state.validConfirmPassword &&
                      <FormFeedback className="d-block password-error">Passwords must match.</FormFeedback>}
                </ReactStrap.FormGroup>
                <Alert color="danger" isOpen={this.props.isError} toggle={() =>
                  this.props.setIsError(!this.props.isError)} >
                  { this.props.errorMessage ? this.props.errorMessage : 'Invalid/Expired Invitation' }
                </Alert>
                {this.props.isLoading && <Loading size="64"></Loading>}
                <Button className="btn-submit"
                  disabled={!this.state.password.length  || this.state.invalidPasswordMessage.length > 0
                    || !this.state.validConfirmPassword || this.props.isLoading}
                >Sign up</Button>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  public updateField(event: any) {
    this.setState({
      ...this.state,
      [event.name]: event.value,
    })
  }

  private googleSignup() {
    this.props.setIsLoading(true)

    const googleClient = google.accounts.oauth2.initCodeClient({
      client_id: window.localStorage.getItem('googleClientId'),
      scope: LoginConstants.GOOGLE_AUTH_SCOPE,
      ux_mode: 'popup',
      callback: (response) => {
        if (response.code) {
          this.props.submitSignup({
            community: this.props.community,
            identity_provider: LoginConstants.GOOGLE_IDENTITY_PROVIDER,
            idp_code: response.code,
            invitation_token: this.props.token,
          })
        } else {
          this.props.setIsLoading(false)
        }
      },
      error_callback: (error) => {
        this.props.setIsLoading(false)
      },
    })
    googleClient.requestCode()
  }
}

export const SignupComponent = connect(
  ({signup}: any) => ({
    community: signup.community,
    communityName: signup.communityName,
    email: signup.email,
    errorMessage: signup.errorMessage,
    facebookAppId: signup.facebookAppId,
    isLoading: signup.isLoading,
    isError: signup.isError,
    token: signup.token}),
  {
    submitSignup: Actions.submitSignup,
    setIsLoading: Actions.setIsLoading,
    setIsError: Actions.setIsError,
    linkedInSignup: Actions.linkedInSignup,
  },
)(SignupComponentClass)
