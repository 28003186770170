import * as ReduxObservable from 'redux-observable'
import * as Request from 'request'
import * as Router from 'router'
import {map, mergeMap} from 'rxjs/operators'
import * as Actions from './actions'
import * as Constants from './constants'

export const forgotPasswordSuccess: ReduxObservable.Epic = (action$, state$) =>
  action$.ofType(Request.successConstant(Constants.SUBMIT_FORGOT_PASSWORD))
    .pipe(
      map((res) =>
      Router.redirect(`${Constants.FORGOT_PASSWORD_SUCCESS_ROUTE}`)),
    )

const forgotPasswordFailed: ReduxObservable.Epic = (action$, state$, {window}) =>
action$.ofType(Request.errorConstant(Constants.SUBMIT_FORGOT_PASSWORD))
  .pipe(
    mergeMap(() => [
      Actions.setIsLoading(false),
      Actions.setServerError(true),
    ]),
  )

export const epic = ReduxObservable.combineEpics(forgotPasswordSuccess, forgotPasswordFailed)
