import { DashboardMenuOption } from 'components/dashboard-menu/component'
import React from 'react'
import { PreviewComponent } from './component'

export const routes = [
  {
    action: () => <PreviewComponent />,
    path: DashboardMenuOption.preview,
  },
]
