import * as Request from 'request'
import * as LoginConstants from '../login/constants'
import * as Constants from './constants'
import * as Types from './types'

export const linkedInSignup = () => ({type: Constants.LINKEDIN_SIGNUP})

export const setFacebookAppId = (value: string) => ({type: LoginConstants.SET_FACEBOOK_APP_ID, value})

export const setToken = (token: string) => ({type: Constants.SET_TOKEN, token})
export const setCommunity = (community: string) => ({type: Constants.SET_COMMUNITY, community})
export const setCommunityName = (communityName: string) => ({type: Constants.SET_COMMUNITY_NAME, communityName})
export const setIsLoading = (isLoading: boolean) => ({type: Constants.SET_IS_LOADING, isLoading})
export const setEmail = (email: string) => ({type: Constants.SET_EMAIL, email})
export const setIsError = (isError: boolean) => ({type: Constants.IS_SIGNUP_ERROR, isError})
export const setErrorMessage = (message: string) => ({type: Constants.SIGNUP_ERROR_MESSAGE, message})

export const getInvitationInfo = (payload: Types.InvitationInfoPayload) =>
Request.requestActionCreator(Constants.INVITATION_USER_INFO)
({
  method: 'get',
  url: `/invite/user-info?community=${payload.community}&invitation_token=${payload.invitation_token}`,
})

export const submitSignup = (payload: Types.SignupPayload) => Request.requestActionCreator(Constants.SUBMIT_SIGNUP)
({
  method: 'post',
  payload,
  url: '/invite/signup',
})

export const getSSOClients = () => Request.requestActionCreator(LoginConstants.SSO_CLIENTS)
({
  method: 'get',
  url: '/sso-clients',
})
