import React from 'react'
import {LoginComponent} from './component'
import {LOGIN_BASE_ROUTE} from './constants'
import {MobileLogin} from './mobile/component'

export const routes = [
  {
    action: (action) =>
     action.store.getState().config.isDesktop
        ? <LoginComponent />
        : <MobileLogin />,
    path: LOGIN_BASE_ROUTE,
  },
]
