declare const RASA_ONBOARDING_BASE_URL: string
export const LOGIN_BASE_ROUTE = '/login'

export const REDIRECT_TO = 'REDIRECT_TO'
export const HUBSPOT_REDIRECT_TO = 'HUBSPOT_REDIRECT_TO'
export const HUBSPOT_REDIRECT_TO_QUERY = 'hr'

export const SSO_CLIENTS = 'SSO_CLIENTS'
export const COGNITO_LOGIN = 'COGNITO_LOGIN'
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN'
export const GOOGLE_LOGIN_CALLBACK = 'GOOGLE_LOGIN_CALLBACK'
export const LINKEDIN_LOGIN = 'LINKEDIN_LOGIN'
export const SUBMIT_LOGIN = 'SUBMIT_LOGIN'
export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const EXPIRE_REDIRECT_URL = 'https://rasa.io/trial-expiration'

export const SET_FACEBOOK_APP_ID = 'SET_FACEBOOK_APP_ID'

export const COGNITO_IDENTITY_PROVIDER = 'cognito'
export const GOOGLE_IDENTITY_PROVIDER = 'google'
export const GOOGLE_AUTH_SCOPE = 'profile email'
export const FACEBOOK_IDENTITY_PROVIDER = 'facebook'
export const APPLE_IDENTITY_PROVIDER = 'apple'
export const APPLE_CLIENT_ID = 'io.rasa.login-with-apple'
export const APPLE_AUTH_SCOPE = 'name email'
export const APPLE_AUTH_STATE = 'dashboard'

export const signupUrl = (): string => RASA_ONBOARDING_BASE_URL + '?direct=true'
