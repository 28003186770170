import {DashboardMenu, DashboardMenuOption} from 'components/dashboard-menu/component'
import {GenericNavigatorSelectedItemProps} from 'components/generic-navigator/component'
import {DesktopNavigator} from 'components/generic-navigator/desktop'
import {Analytics} from 'components/icons/analytics'
import { RasaContext } from 'context'
import React, {Component} from 'react'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import * as Constants from './constants'
import * as Flash from '../../components/flash'
import { getValueWithRetry } from '../../generic/utility'

//TODO: fix CSS of left navbar here and in schedule page.

const analytics = <Analytics svgwidth={72} />

interface AnalyticsComponentState {
  hasAbReportsAccess: boolean,
  hasReportsAccess: boolean,
  hasWeeklyReportAccess: boolean,
  isSuperUser: boolean,
  retryCount: number,
}

export class AnalyticsComponent extends Component<GenericNavigatorSelectedItemProps, AnalyticsComponentState> {

  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: GenericNavigatorSelectedItemProps) {
    super(props)
    this.state = {
      hasWeeklyReportAccess: false,
      hasReportsAccess: false,
      hasAbReportsAccess: false,
      isSuperUser: false,
      retryCount: 1,
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    getValueWithRetry(this.sharedStore, SharedKeys.activeCommunity).then((activeCommunity) => {
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.setState({
        hasWeeklyReportAccess: activeCommunity.role === Roles.super_admin ||
          avlFeatures.indexOf(BillingPlanDetailCode.WEEKLY_REPORT) > -1,
        hasReportsAccess: activeCommunity.role === Roles.super_admin ||
          avlFeatures.indexOf(BillingPlanDetailCode.REPORTS) > -1,
        hasAbReportsAccess: avlFeatures.indexOf(BillingPlanDetailCode.AB_TESTS) > -1,
        isSuperUser: activeCommunity.role === Roles.super_admin,
      })
    }).catch((error) => {
      this.context.store.dispatch(Flash.showFlashError(error))
    })
  }

  public render() {
    return (
      <div>
        <DashboardMenu selected={DashboardMenuOption.analytics} />
        <div>
          <DesktopNavigator
              selectedItem={this.props.selectedItem}
              sections={Constants.sections(
                this.state.hasWeeklyReportAccess,
                this.state.hasReportsAccess,
                this.state.hasAbReportsAccess,
                this.state.isSuperUser)}
              baseRoute={DashboardMenuOption.analytics}
              title="Analytics"
              subTitle="Use your analytics to gain insights on your audience"
              headerIcon={analytics}/>
        </div>
      </div>
    );
  }
}
