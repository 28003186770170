import * as Request from 'request'
import * as Constants from './constants'

export const setEmail = (email: string) => ({type: Constants.FA_SET_EMAIL, email})
export const setIsLoading = (isLoading: boolean) => ({type: Constants.FA_SET_IS_LOADING, isLoading})
export const setServerError = (serverError: boolean) =>
  ({type: Constants.FA_SET_SERVER_ERROR, serverError})
export const setResendSuccess = (resendSuccess: boolean) =>
  ({type: Constants.FA_SET_RESEND_SUCCESS, resendSuccess})

export const submit2fa = (email: string, confirmationCode: string, expiration: string) =>
Request.requestActionCreator(Constants.SUBMIT_2FA)
({
  method: 'post',
  payload: {email, confirmation_code: confirmationCode, confirmation_code_expiration: expiration},
  url: '/auth/validate-2fa',
})

export const resend2fa = (email: string) =>
Request.requestActionCreator(Constants.RESEND_2FA)
({
  method: 'post',
  payload: {email},
  url: '/auth/send-2fa',
})
