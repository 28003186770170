import {AnchorButtonLink} from 'components/buttons'
import {DashboardMenuOption} from 'components/dashboard-menu/component'
import {EmailSent} from 'components/icons/emailSent'
import {RasaLogo} from 'components/icons/rasalogo'
import {Loading} from 'components/loading'
import {AjaxWrapper, HttpMethod} from 'generic/ajaxWrapper'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button} from 'reactstrap'
import * as Router from 'router'

import './styles.css'

enum VerifyEmailResponseCode {
  SUCCESS = 0,
  EXPIRED = 1,
  ALREADY_VERIFIED = 2,
  INVALID_GUID_OR_CODE = 3,
  SQL_FAILURE = -1,
}

interface VerifyEmailProps {
  push: any,
}

interface VerifyEmailState {
  verifying: boolean,
  success: boolean,
  codeProvided: boolean,
  code: string,
  serverMessage?: string,
  serverResponseCode?: VerifyEmailResponseCode,
  emailStatus: string,
  emailSentTo?: string,
}

class BaseComponent extends Component<VerifyEmailProps, VerifyEmailState>  {
  constructor(props: VerifyEmailProps) {
    super(props)
    this.state = {
      code: null,
      codeProvided: null,
      emailStatus: null,
      success: false,
      verifying: false,
    }
  }

  public componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('c');

    if (code) {
      this.setState({
        code,
        codeProvided: true,
        verifying: true,
        success: null,
      })
      this.verifyCode(code)
    } else {
      this.setState({
        code: null,
        codeProvided: false,
        success: null,
        verifying: false,
      })
    }
  }

  public render() {
    return (
      <div className="email-verify-body-wrapper">
        <div className="dashboard-menu">
          <div className={'dashboard-menu-item dashboard-rasa-logo'}
              onClick={() => this.handleClick(DashboardMenuOption.dashboard)}>
            <RasaLogo/>
          </div>
        </div>
        <div className="email-verify-inner-wrapper">
          <div className="icon">
            <EmailSent/>
          </div>
          <div className="body">
            <div className="title">{this.title()}</div>
            <div className="summary">{this.description()}</div>
            <div className="action">{this.action()}</div>
          </div>
        </div>
      </div>
    )
  }

  private action = () => {
    if (!this.state.verifying) {
      if (this.state.success) {
        return this.redirectToHome()
      } else if (this.state.serverResponseCode !== VerifyEmailResponseCode.SQL_FAILURE) {
        return this.resendEmail()
      }
    }
    return null
  }

  private title = () => {
    if (this.state.verifying) {
      return 'Hang tight....'
    } else if (!this.state.codeProvided) {
      return 'Something\'s Wrong'
    } else if (this.state.emailStatus === 'success') {
      return 'Check your Inbox'
    } else if (this.state.emailStatus === 'failed') {
      return 'Something\'s Wrong'
    } else if (this.state.success) {
      return 'Your Email has been verified'
    } else {
      return 'There was a problem with your code'
    }
  }

  private description = () => {
    if (this.state.verifying) {
      return <div>We are verifying your code</div>
    } else if (!this.state.codeProvided) {
      return <div>
          There is a problem with your request, the email link you clicked on is invalid,
          <a href="mailto:help@rasa.io">please contact rasa.io customer success for assistance</a>
        </div>
    }

    if (this.state.emailStatus === 'success') {
      return <div>
        An email was sent to {this.state.emailSentTo
        }, please check your inbox and click on the link in the message.
      </div>
    } else if (this.state.emailStatus === 'failed') {
      return <div>
        There was an error sending the email, please contact &nbsp;
        <a href="mailto:help@rasa.io">Customer Success for assistance</a>
      </div>
    }

    switch (this.state.serverResponseCode) {
      case VerifyEmailResponseCode.SUCCESS:
        return <span>Your email has been verified, you're a real person, congrats!</span>
      case VerifyEmailResponseCode.ALREADY_VERIFIED:
        return <span>Your account email has already been verified, no further action required.</span>
      case VerifyEmailResponseCode.EXPIRED:
        return <span>
          The link you clicked on has expired.
          Click the link below and we will send you a new email with a new token.
        </span>
      case VerifyEmailResponseCode.INVALID_GUID_OR_CODE:
        return <span>
          The code that was provided was invalid.
          Click the link below and we will send you a new email with a new token.
        </span>
      case VerifyEmailResponseCode.SQL_FAILURE:
      default:
        return <span>
                  An internal error occured, please try again soon or
                  <a href="mailto:help@rasa.io">click here to contact</a>
                  the rasa.io Customer Success team. We apologize for the inconvenience.
                </span>

    }
  }

  private redirectToHome(): JSX.Element {
    return <div className="email-verify-dashboard-redirect-message">
      <AnchorButtonLink href={DashboardMenuOption.dashboard}>
        <div>Woo Hoo!</div>
      </AnchorButtonLink>
      {this.doRedirect()}
    </div>
  }

  private doRedirect() {
    // return nothing
    setTimeout(() => {
      window.location.replace(`${DashboardMenuOption.dashboard}`) // redirect to root of the application
    }, 5000)
  }

  private handleClick(item: string) {
    this.props.push(item);
  }

  private resendEmail(): JSX.Element {
    return <div>
      <Button onClick={() => this.doSendNewEmail()}
                        disabled={this.state.emailStatus !== null}>
        <div>Send Email</div>
      </Button>
      {this.state.emailStatus === 'pending' && <Loading size="32"></Loading>}
    </div>
  }

  private doSendNewEmail() {
    this.setState({
      ...this.state,
      emailStatus: 'pending',
    }, () => {
      // this fires off a request to the server to generate a new verification code
      // and email it to the accont holder
      const url: string = AjaxWrapper.getServerUrl() + '/verify-email'
      AjaxWrapper.ajax(url, HttpMethod.POST, {})
      .then((emailResult) => {
        if (emailResult.code === 0) {
          this.setState({
            ...this.state,
            emailStatus: 'success',
            emailSentTo: emailResult.payload.email_address,
          })
        } else {
          this.setState({
            ...this.state,
            emailStatus: 'failed',
          })
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
        this.setState({
          ...this.state,
          emailStatus: 'failed',
        })
      })
    })
  }

  private verifyCode(code: string) {
    const url: string = AjaxWrapper.getServerUrl() +
              `/verify-email/verify/${code}`;
    return AjaxWrapper.ajax(url, HttpMethod.POST, {})
      .then((newData) => {
        this.setState({
          success: (
            newData.code === VerifyEmailResponseCode.SUCCESS ||
            newData.code === VerifyEmailResponseCode.ALREADY_VERIFIED),
          serverMessage: newData.message,
          serverResponseCode: newData.code,
          verifying: false,
        }) //setting state results in us rendering again
      })
      .catch((error) => {
        this.setState({
          verifying: false,
          success: false,
        })
      })
  }
}

export const VerifyEmailComponent = connect(
  null,
  {
    push: Router.push,
  },
  )(BaseComponent)
