import { DashboardMenu, DashboardMenuOption } from 'components/dashboard-menu/component'
import { GenericNavigatorSelectedItemProps } from 'components/generic-navigator/component'
import { DesktopNavigator } from 'components/generic-navigator/desktop'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import React, { Component } from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { PersonInfo } from 'user'
import * as Constants from './constants'
import { PanelBarOption } from '../../components/panel-bar/types'
import { getValueWithRetry } from '../../generic/utility'
import * as Flash from '../../components/flash'

interface ScheduleComponentState {
  activeCommunity: any,
  isLoading: boolean,
  person: PersonInfo,
  sections: PanelBarOption[],
}

export class ScheduleComponent extends Component<GenericNavigatorSelectedItemProps, ScheduleComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: any) {
    super(props)
    this.state = {
      activeCommunity: null,
      isLoading: true,
      person: null,
      sections: [],
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    getValueWithRetry(this.sharedStore, SharedKeys.activeCommunity)
      .then((activeCommunity) => {
        Promise.all([
          this.sharedStore.getValue(SharedKeys.person),
        ]).then(([person]) => {
          const sections = Constants.sections(activeCommunity.billingInfo.currentPlan)
          this.setState({activeCommunity, person}, () => {
            this.setState({
              isLoading: false,
              sections
            })
          })
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        }, () => {
          this.context.store.dispatch(Flash.showFlashError(error))
        })
      })
  }

  public render() {
    return (
      this.state.isLoading ? <Loading size="64" /> :
      <div>
        <DashboardMenu selected={DashboardMenuOption.schedule} />
        <div>
          <DesktopNavigator
            selectedItem={this.props.selectedItem}
            sections={this.state.sections}
            baseRoute={DashboardMenuOption.schedule}
            title="Schedule"
            subTitle="Manage your schedule here"
          />
        </div>
      </div>
    )
  }
}
