import * as Redux from 'redux'
import * as Constants from './constants'

export const reducer = Redux.combineReducers({
  email: (state = null, {type, email}) => type === Constants.RESET_PASSWORD_SET_EMAIL ? email : state,
  code: (state = null, {type, code}) => type === Constants.RESET_PASSWORD_SET_CODE ? code : state,
  isLoading: (state = false, {type, isLoading}) => type === Constants.RESET_PASSWORD_SET_IS_LOADING ? isLoading : state,
  serverError: (state = false, {type, error, message}) => {
    return type === Constants.RESET_PASSWORD_SET_SERVER_ERROR ? { error, message } : state
  },
})
