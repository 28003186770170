import {DashboardMenu, DashboardMenuOption} from 'components/dashboard-menu/component'
import { Dashboard } from 'components/dashboard/dashboard';
import { RasaContext } from 'context'
import React, {Component} from 'react'

import { HANDLE_LOGOUT } from '../logout/constants'

export class RootComponent extends Component {

  public static contextType = RasaContext

  public componentDidMount() {
    window.onunhandledrejection = (err) => {
      // handle error
      if (this.isAuthenticationError(err)) {
        this.context.store.dispatch({type: HANDLE_LOGOUT})
      } else {
        throw err
      }
    }
  }

  public render() {
    return (
      <div>
        <DashboardMenu selected={DashboardMenuOption.dashboard} />
          <Dashboard/>
      </div>
    )
  }

  private isAuthenticationError(error) {
    return error && error.reason && error.reason.status === 401
  }

}
