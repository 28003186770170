import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import {RasaLogo} from 'components/icons/rasalogo'
import React, {Component} from 'react'
import * as ReactStrap from 'reactstrap'
import * as GlobalConstants from '../../constants'
import './styles.css'

const {Col, Row} = ReactStrap

interface Props {
  content: any,
  heading: string,
}

export class SuccessPageComponent extends Component<Props> {
  constructor(props: Props) {
    super(props, '');
  }

  public render() {
    return (
      <div className="success-page">
        <div className="dashboard-menu">
          <div className={'dashboard-menu-item dashboard-rasa-logo'}
            onClick={() => this.handleClick(DashboardMenuOption.dashboard)}>
            <RasaLogo/>
          </div>
          <div className="dashboard-popup-menu-item clickable-item need-a-help">
            <a href={GlobalConstants.RASA_HELP_BASE_URL} target="_blank" rel="noopener">Help & Support</a>
          </div>
        </div>
        <Row className="success-page-wrapper">
          <Col md="6" lg="12" className="content">
            <h1>{this.props.heading || 'Success'}</h1>
            <p className="enter-paragraph">{this.props.content}</p>
          </Col>
        </Row>
      </div>
    )
  }

  private handleClick(item: DashboardMenuOption) {
    window.location.replace(item)
  }
}
